$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;

.gallery {
  margin: auto;
  --slide-height: 320px;
  --slide-height-md: 460px;
}
.gallery__viewport {
  overflow: hidden;
}
.gallery__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
}
.gallery__slide {
  flex: 0 0 auto;
  min-width: 0;
  transition: opacity 0.2s ease-in-out;

  &:not(:global(.is-snapped)) {
    opacity: 0.2;
  }
}
.gallery__slide__img {
  img {
    border-radius: 1.8rem;
    display: block;
    max-height: var(--slide-height);
    width: 100%;
    object-fit: contain;

    @media #{variables.$above-md} {
      max-height: var(--slide-height-md);
    }
  }
}
.gallery__slide__video {
  display: flex;
  justify-content: center;

  video {
    max-height: var(--slide-height);
  }
}
.gallery__nav-button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.1rem rgba(255, 255, 255, 0.4);
  width: 3rem;
  height: 3rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  align-items: center;
  justify-content: center;
}
.gallery__nav-button:disabled {
  display: none;
}
.gallery--single-item {
  :not(:global(.is-snapped)) {
    opacity: 1;
  }
}
