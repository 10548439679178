$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/colors' as colors;
@use '../../../styles/variables' as variables;

section.n4-youtube {
  justify-content: center;
  align-self: center;
  padding: 3rem 0;
  max-width: 810px;
  @media #{variables.$xs, variables.$md} {
    padding: 0;
  }

  @media #{variables.$xs, variables.$md} {
    margin: 3rem 0;
  }

  @media #{variables.$lg, variables.$laptop, variables.$desktop-to-wide} {
    padding-left: 0;
    padding-right: 0;
  }

  img,
  div.video {
    flex-shrink: 0;
    border-radius: 1.5rem;

    div {
      margin-top: 0;
      a img {
        @media #{variables.$xs, variables.$md, variables.$lg, variables.$laptop, variables.$desktop-to-wide} {
          max-width: 100%;
          min-width: 100%;
        }
      }
    }

    @media #{variables.$xs} {
      max-width: 100%;
    }
  }

  p.captions-bottom {
    max-width: 50vw;
    @media #{variables.$xs, variables.$md, variables.$lg} {
      max-width: 100%;
    }
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    min-height: 1.3125rem;
    border-left: 2px solid colors.$navy-blue;
    padding-left: 0.5rem;
    margin-bottom: 0;

    span {
      flex: 1 0 0;
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      @media #{variables.$xs} {
        font-weight: 350;
        font-size: 1.15rem;
      }
    }
  }

  .modal-backdrop {
    opacity: 0.9 !important;
  }

  .floating-close {
    top: -32px;
    right: 0;
    position: absolute;
  }

  .video_container {
    background-color: black;
  }

  .btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .video-link {
    position: relative;
    margin: 25px 0 20px;

    img {
      width: 100%;
    }
  }
}
