$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/colors' as colors;
@use '../../../styles/variables' as variables;

section.author {
  max-width: 640px;
  margin: 3rem auto 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  gap: 1rem;

  @media #{variables.$xs} {
    flex-direction: column;
    gap: 1.5rem;
  }

  section.author-top {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    section.author-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.8125rem;
      max-width: 27.5rem;

      @media #{variables.$xs} {
        max-width: 100%;
        gap: 1.5rem;
      }

      section.author-intro {
        display: flex;
        align-items: center;
        gap: 1rem;

        section.intro {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h1,
          h4 {
            margin: 0;
            padding: 0;
            color: colors.$navy-blue;
            font-family: var(--hankenGrotesk-font);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;
            letter-spacing: -0.0125rem;
          }

          span {
            color: colors.$navy-blue;
            font-family: var(--hankenGrotesk-font);
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.4rem;
            letter-spacing: -0.01rem;
          }
        }
      }

      // section.author-description {
      // }
    }

    section.author-connect {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;

      @media #{variables.$xs} {
        align-items: flex-start;
      }
    }
  }
}
