$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/colors' as colors;
@use '../../../../../styles/variables' as variables;
.product-scorecard {
  border-radius: 1.25rem;
  border: 1px solid colors.$gray-border;
  background: #fff;
  box-shadow: 4px 12px 40px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  max-width: 28.75rem;
  padding: 2rem 1.75rem;
  flex-direction: column;

  .top {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-bottom: 1.9rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #d4d4d4;
    @media (max-height: 750px) and (min-width: 1040px) {
      border-bottom: none;
      padding-bottom: 0;
    }
    &-right {
      &-label {
        color: rgba(20, 37, 70, 0.8);

        font-size: 0.875rem;
        font-style: normal;
        line-height: 140%;
        letter-spacing: -0.02625rem;
        @media #{variables.$xs} {
          font-size: 1rem;
        }
      }
      &-title {
        color: colors.$navy-blue;

        font-size: 1.25rem;
        line-height: 140%;
        letter-spacing: -0.0375rem;
        margin-top: 0.38rem;
        @media #{variables.$xs} {
          font-size: 1.5rem;
        }
      }
      &-link {
        display: block;
        margin-top: 0.5rem;

        a {
          color: colors.$primary-blue;
          border-color: colors.$primary-blue;
          font-size: 0.875rem;
          font-style: normal;
          line-height: 140%;
          letter-spacing: -0.02625rem;
          @media #{variables.$xs} {
            font-size: 0.95rem;
          }
        }
      }
    }
  }

  .middle {
    border-bottom: 1px solid #d4d4d4;
    padding: 1.75rem 0;

    &-subtitle {
      color: colors.$navy-blue;

      font-size: 1.3rem;
      font-style: normal;
      line-height: 2.6rem;
      letter-spacing: -0.039rem;
    }

    .tags {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      margin: 1.25rem 0 0;
      padding-left: 0.1rem;

      li {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        list-style: none;

        span {
          color: colors.$navy-blue;

          font-size: 1rem;
          font-style: normal;
          line-height: 140%;
          letter-spacing: -0.03rem;
        }
      }
    }
  }

  &-action-button {
    justify-content: center;
    border-radius: 20px;
  }
}
