$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/variables' as variables;
@use '../../../../styles/colors' as colors;

section.product {
  display: flex;
  width: 100%;
  gap: 1.1875rem;
  align-items: flex-start;
  justify-content: stretch;
  max-width: 29.938rem;
  max-height: 6.75rem;
  overflow-y: hidden;

  @media #{variables.$xs, variables.$md} {
    max-height: 8rem;
    max-width: 100%;
  }

  figure {
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    aspect-ratio: 144/108;
    max-height: 6.75rem;
    border-radius: 0.625rem;
    min-width: 9rem;
    max-width: 9rem;

    @media #{variables.$xs, variables.$md} {
      aspect-ratio: 88/69;
      max-width: 10rem;
      max-height: 12rem;
    }

    img {
      object-fit: contain;
      width: auto;
      height: auto;
    }

    div.placeholder {
      min-width: 9rem;
      max-width: 9rem;
      border-radius: 0.625rem;
      background-color: colors.$navy-blue-200;

      @media #{variables.$xs, variables.$md} {
        aspect-ratio: 88/69;
        min-width: 5.5rem;
        max-width: 5.5rem;
        max-height: 4.3125rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(lightgray, lightgray);
      border-radius: 0.625rem;
      pointer-events: none;
      opacity: 0.188;
    }
  }

  section.details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;

    h4 {
      padding: 0;
      margin: 0;
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.35rem;
      letter-spacing: -0.03375rem;
      flex-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media #{variables.$xs, variables.$md} {
        font-weight: 500;
        font-size: 1.25rem;
        -webkit-line-clamp: 1;
      }
    }

    div.description {
      padding: 0;
      margin: 0;
      color: colors.$navy-blue-600;
      font-family: var(--hankenGrotesk-font);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.225rem;
      letter-spacing: -0.02625rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @media #{variables.$xs, variables.$md} {
        font-size: 1rem;
      }
    }
    div.price {
      display: flex;
      color: colors.$primary-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.225rem;
      letter-spacing: -0.02625rem;
    }
  }
}
