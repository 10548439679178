$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

.article-container-markdown + .article-container-markdown {
  margin-top: 1.5rem;
}

section.n4-article {
  display: flex;
  padding-top: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  max-width: 100%;
  @media #{variables.$desktop} {
    width: 1368px;
  }
  p + p {
    margin-top: 1rem;
  }
  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    margin-top: 1.5rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--hankenGrotesk-font);
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  h1,
  h1 strong {
    font-size: 2.8rem;
  }

  h2,
  h2 strong {
    font-size: 2.2rem;
  }

  h3,
  h3 strong {
    font-size: 2rem;
  }

  h4,
  h4 strong {
    font-size: 1.8rem;
  }

  h5,
  h5 strong {
    font-size: 1.6rem;
  }

  h6,
  h6 strong {
    font-size: 1.6rem;
  }

  p,
  li {
    font-size: 1.3rem;
  }

  @media #{variables.$xs} {
    padding-top: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  div.article-info-image {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media #{variables.$xs} {
      flex-direction: column-reverse;
    }
  }

  section.article-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-width: 640px;
    align-self: stretch;

    @media #{variables.$above-laptop} {
      max-width: 640px;
    }

    @media #{variables.$xs, variables.$md} {
      padding: 0;
    }
  }

  section.affiliate-disclosure {
    margin-top: 1rem;
    color: colors.$navy-blue;
    font-family: var(--hankenGrotesk-font);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.975rem;
    letter-spacing: -0.0225rem;
  }

  section.details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @media #{variables.$xs} {
      gap: 1.5rem;
      max-width: 100%;
    }

    section.meta {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      min-height: 2.1875rem;

      @media #{variables.$xs} {
        padding-top: 0;
      }

      div {
        flex: none;
        display: flex;
        padding: 0.625rem 1.25rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 3.4375rem;
        background: #baceed;
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2rem;
        letter-spacing: -0.01rem;
      }

      span {
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.03269rem;

        @media #{variables.$xs} {
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
          letter-spacing: -0.01rem;
        }
      }
    }

    h1 {
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 4rem;
      font-style: normal;
      font-weight: 600;
      // font-size: 56px;
      // font-weight: 500;
      line-height: 120%;
      letter-spacing: -1.68px;
      margin-bottom: 0;

      @media #{variables.$xs} {
        width: 100%;
        font-size: 2.2rem;
        font-style: normal;
        line-height: 2.6rem;
        font-weight: 700;
        letter-spacing: -0.025rem;
        // margin-bottom: 1rem;
      }
    }
  }

  section.about {
    align-self: stretch;
    // margin: 2.5rem 0 3rem;

    @media #{variables.$xs} {
      gap: 3.0625rem;
      margin: 0;
    }
    @media #{variables.$md} {
      gap: 3.0625rem;
    }
    section.about-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2.5rem;
      flex-basis: 774px;

      @media #{variables.$xs} {
        gap: 1.5rem;
        flex-direction: column;
      }

      section.about-content-details {
        display: flex;
        max-width: 19.875rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;

        @media #{variables.$xs} {
          margin-top: 20px;
          max-width: 46%;
          min-width: 170px;
          font-size: 1.2rem;
          padding-right: 8%;
        }

        span.about-content-details-top {
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
          letter-spacing: -0.01rem;
          @media #{variables.$xs} {
            font-size: 1.1rem;
          }
        }

        span.about-content-details-bottom,
        div.about-content-details-bottom,
        div.about-content-details-bottom a {
          width: 100%;
          max-width: 19.875rem;
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 1rem;
          font-style: normal;
          font-weight: 300;
          line-height: 1.5rem;
          letter-spacing: -0.01rem;

          @media #{variables.$xs} {
            max-width: 100%;
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  figure.image {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 1.25rem;
    margin-bottom: 3rem;
    max-height: 37.5rem;

    img {
      object-fit: cover;
      border-radius: 1.25rem;
      width: auto;
      height: auto;
    }

    @media #{variables.$xs} {
      aspect-ratio: auto;
      max-height: 28rem;
      margin: 2rem 0;
    }

    div.skeleton {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  section.content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    @media #{variables.$xs} {
      align-items: flex-start;
    }

    section.article {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      width: 100%;
      :global(.ht-youtube):first-child,
      :global(.n4-image):first-child {
        margin: 0;
        padding: 0 0 3rem;
        max-width: 100%;
        @media #{variables.$xs} {
          margin-top: 2rem;
        }
      }

      @media #{variables.$xs} {
        align-items: flex-start;
        justify-items: flex-start;
      }

      h4.article-title {
        margin: 0;
        color: colors.$black;
        font-family: var(--hankenGrotesk-font);
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
        letter-spacing: -0.02rem;
        margin-bottom: 1.875rem;
        margin-top: 5rem;

        @media #{variables.$xs} {
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.8rem;
          letter-spacing: -0.015rem;
          margin-top: 0;
          margin-bottom: 1.25rem;
        }
      }

      p {
        font-family: var(--hankenGrotesk-font);
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 300;
        line-height: 2;
        letter-spacing: -0.015rem;
        @media #{variables.$xs} {
          font-size: 19px;
          font-weight: 400;
          line-height: 1.7;
        }
      }
    }
  }

  section.tags-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 3rem;

    @media #{variables.$xs} {
      flex-direction: column;
      margin-bottom: 3.0625rem;
    }

    section.share {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      @media #{variables.$xs} {
        gap: 1.25rem;
      }

      span {
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.6875rem;

        @media #{variables.$xs} {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.2rem;
          letter-spacing: -0.01rem;
        }
      }
    }

    ul.tags {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      margin-bottom: 0;
      align-items: flex-end;
      justify-content: flex-end;

      @media #{variables.$xs} {
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 0;
      }

      li {
        display: flex;
        padding: 0.25rem 0.75rem;
        border-radius: 3.9375rem;
        background: colors.$sky-50;
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 450;
        line-height: 1.3125rem;
        letter-spacing: -0.00875rem;
        text-wrap: nowrap;
      }
    }
  }

  div.divider {
    border: 1px solid colors.$navy-blue-100;
    width: 100%;
    margin: 3rem 0;

    &--spaceless {
      margin: 0;
    }

    &--mobile-hide {
      @media #{variables.$xs} {
        display: none;
      }
    }
  }
  section.authors {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;

    span.authors-intro {
      margin-bottom: 2rem;
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: -0.0375rem;
    }

    ul.authors-list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: 2rem;

      @media #{variables.$xs} {
        gap: 4rem;
      }

      li.author {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        flex-direction: column;
        gap: 2rem;

        @media #{variables.$xs} {
          flex-direction: column;
          gap: 1.5rem;
        }

        section.author-top {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          section.author-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.8125rem;
            max-width: 27.5rem;

            @media #{variables.$xs} {
              max-width: 100%;
              gap: 1.5rem;
            }

            section.author-intro {
              display: flex;
              align-items: center;
              gap: 1rem;

              section.intro {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                h4 {
                  margin: 0;
                  padding: 0;
                  color: colors.$navy-blue;
                  font-family: var(--hankenGrotesk-font);
                  font-size: 1.25rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 1.5rem;
                  letter-spacing: -0.0125rem;
                }

                span {
                  color: colors.$navy-blue;
                  font-family: var(--hankenGrotesk-font);
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.4rem;
                  letter-spacing: -0.01rem;
                }
              }
            }

            // section.author-description {
            // }
          }

          section.author-connect {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 1rem;

            @media #{variables.$xs} {
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}

:global(.article-within-product) {
  section.n4-article {
    max-width: 640px;
    section.content:first-child {
      padding-top: 0;
    }
  }
  section.about {
    margin-bottom: 0;
  }
  section.about-content div:first-child {
    padding-top: 0 !important;
  }
}

section.article-container:has(:global(.n4-markdown)) + section.article-container :global(.n4-markdown) p:first-child {
  margin-top: 1rem;
}

section.article-container:has(:global(.n4-markdown))
  + section.article-container
  :global(.n4-markdown)
  :is(h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child) {
  margin-top: 1.5rem;
}

// todo: fix spacing on blocks like these that follow each other. Like on best invisible page.
// section.article-container:has(:global(.n4-product-card)) + section.article-container:has(:global(.n4-pros-and-cons)) {
//   margin-top: 0;
// }
