// @import './svg_encoder';

@mixin transition($time) {
  -webkit-transition: all $time ease-out 0s;
  -moz-transition: all $time ease-out 0s;
  -ms-transition: all $time ease-out 0s;
  -o-transition: all $time ease-out 0s;
  transition: all $time ease-out 0s;
}

@mixin transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value;
}

@mixin user-select($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}

@mixin animation($value) {
  -webkit-animation: $value;
  -moz-animation: $value;
  -o-animation: $value;
  animation: $value;
}

@mixin animation-delay($value) {
  -webkit-animation-delay: $value;
  -moz-animation-delay: $value;
  -o-animation-delay: $value;
  animation-delay: $value;
}

@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// ===> functions

@function imgix_url($image, $w: false, $h: false) {
  @if $imgix {
    $u: $imgix + '/' + $image + '?auto=format';
    @if $w {
      $u: $u + '&w=' + $w;
    }
    @if $h {
      $u: $u + '&h=' + $h;
    }
    @return $u;
  } @else {
    @return '../images/' + $image;
  }
}

@function imgix_imgset_dpr($u) {
  @if $imgix {
    @return image-set(url($u + '&dpr=1') 1x, url($u + '&dpr=2') 2x, url($u + '&dpr=3') 3x, url($u + '&dpr=4') 4x, url($u + '&dpr=5') 5x);
  } @else {
    @return image-set(url($u) 1x);
  }
}

/* Note, this doesn't work in any browser yet, but we'll be ready when it does. */
@function imgix_imgset_w($u) {
  @return image-set(url($u + '&w=384') 384w, url($u + '&w=768') 768w, url($u + '&w=1024') 1024w, url($u + '&w=1600') 1600w, url($u + '&w=2048') 2048w, 100vw);
}

@mixin imgix_bg($image, $w: false, $h: false) {
  background-image: url(imgix_url($image, $w: $w, $h: $h)); /* default for browsers not supporting image-set */
  /* browsers don't support using widths on image-set yet so doing it with media queries below.
    @if not($w) and not($h) {
      background-image: imgix_imgset_w(imgix_url($image));
    }
  @if $imgix && not($w) and not($h) {
    @media only screen and (max-width: 384px) {
      background-image: imgix_imgset_dpr(imgix_url($image, $w: 384));
    }
    @media only screen and (max-width: 768px) {
      background-image: imgix_imgset_dpr(imgix_url($image, $w: 768));
    }
    @media only screen and (max-width: 1024px) {
      background-image: imgix_imgset_dpr(imgix_url($image, $w: 1024));
    }
    @media only screen and (max-width: 1600px) {
      background-image: imgix_imgset_dpr(imgix_url($image, $w: 1600));
    }
    @media only screen and (max-width: 2048px) {
      background-image: imgix_imgset_dpr(imgix_url($image, $w: 2048));
    }
  }
  @media only screen {
    background-image: imgix_imgset_dpr(imgix_url($image, $w: $w, $h: $h));
  }
  */
}

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// ===> animations

@keyframes transitionScaleOpen {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
@keyframes transitionScaleClose {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

@mixin scaleOpen($duration: 0.5s) {
  transform-origin: top;
  animation: transitionScaleOpen $duration ease-out forwards;
  will-change: transform;
}
@mixin scaleClose($duration: 0.5s) {
  transform-origin: top;
  animation: transitionScaleClose $duration ease-in forwards;
  will-change: transform;
}

// this animation works better if you know height of element
// otherwise second best is knowing approximate height
@mixin dropdown($duration: 0.3s, $max-height: 100rem) {
  transform: translate3d(0, -1rem, 0);
  opacity: 1;
  display: none;
  max-height: 0;
  transition:
    opacity $duration,
    transform $duration,
    max-height $duration;
  display: $duration;

  &--open {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    max-height: $max-height;
    display: flex;
  }
}

@mixin rotate($duration: 0.3s, $scale: 1) {
  transition: transform $duration ease;

  &--rotate-180 {
    transform: rotate(180deg) scale($scale);
  }

  &--rotate-90 {
    transform: rotate(90deg) scale($scale);
  }
}

// use with useAnimation hook
// --show and --hide included here, just need to apply in jsx file
// Example: see components/common/slideout
@mixin slide($duration: 0.3s) {
  transform: translate3d(-100%, 0, 0);
  transition: transform $duration ease-out;
  will-change: transform;

  &--show {
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }

  &--hide {
    transform: translate3d(-100%, 0, 0);
    will-change: transform;
  }
}

// use with useAnimation hook
// --fade included here, just need to apply in jsx file
// Example: see components/common/slideout
@mixin fadeBackground($durationShow: 0.3s, $durationHide: 0.2s, $backgroundColor: rgba(0, 0, 0, 0.75)) {
  transition: background-color $durationShow ease-out;
  will-change: background-color;

  &--show {
    background-color: $backgroundColor;
  }

  &--hide {
    background-color: transparent;
    transition:
      background-color $durationHide ease-out,
      opacity $durationHide ease-out;
  }
}

@mixin fadeInFromTop($durationShow: 0.3s, $durationHide: 0.2s) {
  opacity: 0;
  transform: translate3d(0, -2rem, 0);
  transition:
    transform $durationShow ease-out,
    opacity $durationShow ease-out;
  will-change: opacity, transform;

  &--show {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &--hide {
    opacity: 0;
    transform: translate3d(0, -2rem, 0);
    transition:
      transform $durationHide ease-out,
      opacity $durationHide ease-out;
  }
}

// ===> styles

@mixin overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
