$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

section.mobile {
  display: none;

  a {
    @media #{variables.$sm} {
      min-width: 498px;
      max-width: 498px;
    }

    @media #{variables.$md} {
      min-width: 624px;
      max-width: 624px;
    }
  }

  @media #{variables.$xs, variables.$md} {
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    background: colors.$white;
    min-width: 100vw;
    min-height: 32.5rem;
    max-height: 32.5rem;
    justify-content: space-between;

    a {
      width: 100%;
      align-items: center;
      justify-content: center;
      align-self: center;
    }

    button {
      // This is the search button
      border: none;
      width: 100%;
      align-items: center;
      justify-content: center;
      align-self: center;
    }

    section.content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
      width: 100%;
      height: 100%;

      @media #{variables.$sm, variables.$md} {
        align-self: center;
      }

      @media #{variables.$sm} {
        min-width: 498px;
        max-width: 498px;
      }

      @media #{variables.$md} {
        min-width: 624px;
        max-width: 624px;
      }

      div.form-wrapper {
        display: flex;
        width: 100%;

        section.form {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;

          input {
            padding: 0;
            margin: 0;
            border: 0;
            display: flex;
            padding-bottom: 0.75rem;
            padding-left: 3.5rem;
            min-height: 3.75rem;
            justify-content: space-between;
            align-items: flex-end;
            align-self: stretch;
            flex: 1 0 0;
            color: colors.$navy-blue;
            font-family: var(--hankenGrotesk-font);
            padding-bottom: 0rem;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.75rem;
            letter-spacing: -0.0375rem;
            border-bottom: 1px solid colors.$navy-blue-100;

            &:focus,
            &:active {
              outline: none;
              border-bottom: 1px solid colors.$navy-blue;
            }

            &::placeholder {
              font-weight: 400;
              color: colors.$navy-blue-200;
              padding-bottom: 0.75rem;
              border-bottom: 0;
            }

            &::-webkit-input-placeholder {
              font-weight: 400;
              color: colors.$navy-blue-200;
              padding-bottom: 0.75rem;
            }

            &:-moz-placeholder {
              font-weight: 400;
              color: colors.$navy-blue-200;
              padding-bottom: 0.75rem;
            }
          }

          div {
            background: none;
            border: none;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);
            background: #f4f4f4;
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
            z-index: 4;
            left: 0;
            top: 50%;

            i.magnifying-glass {
              min-width: 1.25rem;
              min-height: 1.25rem;

              @media #{variables.$xs} {
                min-width: 1.5rem;
                min-height: 1.5rem;
              }

              filter: invert(9%) sepia(29%) saturate(3922%) hue-rotate(205deg) brightness(94%) contrast(91%);
            }
          }
        }
      }

      section.items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;

        button.item {
          background: none;
          border: none;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 2rem;
          font-style: normal;
          font-weight: 500;
          line-height: 2.4rem;
          letter-spacing: -0.06rem;

          i {
            width: 0.675rem;
            height: 1rem;
            filter: invert(9%) sepia(29%) saturate(3922%) hue-rotate(205deg) brightness(94%) contrast(91%);
          }
        }
      }
    }
  }
}

section.storyblok-nav {
  position: fixed;
  width: 100%;
  min-height: 5rem;
  top: 0;
  z-index: 1000;
  background-color: colors.$orange-50;

  &--transparent {
    background-color: transparent;
  }

  &--active {
    box-shadow: rgba(0, 0, 0, 0.1) 4px 4px 40px 0px;
  }

  button.popover-trigger {
    visibility: hidden;
    position: absolute;
    // popover offset based on this button
    height: 5rem;
    max-width: 0.1px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  section.items {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    pointer-events: none;

    @media #{variables.$xs, variables.$md} {
      display: none;
    }

    section.item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      height: 100%;
      pointer-events: none;

      button {
        pointer-events: auto;
        padding: 0;
        display: flex;
        margin: 0rem 0.75rem;
        gap: 0.375rem;
        align-items: center;
        background: transparent;
        color: colors.$navy-blue;
        border: none;
        font-family: var(--hankenGrotesk-font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: -0.03rem;
        text-wrap: nowrap;

        i {
          width: 0.75rem;
          height: 0.75rem;
          margin-bottom: 0.125rem;
          filter: invert(10%) sepia(14%) saturate(6462%) hue-rotate(199deg) brightness(94%) contrast(92%);
        }
      }
      button.white {
        color: colors.$orange-50;
        filter: none;

        i {
          filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(104%) contrast(106%) !important;
        }
      }
    }

    &--abram-nav-page {
      flex-direction: column;

      section.item {
        flex-direction: column;
        align-self: center;

        button {
          align-self: flex-start;
        }
      }
    }
  }

  section.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;
    min-height: 2.813rem;

    .n4-topbar-button {
      color: colors.$white;
      font-family: var(--hankenGrotesk-font);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: -0.01rem;
      padding: 0.75rem 1.5rem;
      min-height: auto;
      span.text {
        display: flex;
      }
      span.text--sm {
        display: none;
      }

      @media #{variables.$xs, variables.$md, variables.$lg} {
        span.text {
          display: none;
        }
        span.text--sm {
          display: flex;
        }
      }

      @media #{variables.$xs} {
        min-width: 7.25rem;
      }
    }

    section.search {
      display: flex;

      @media #{variables.$md, variables.$xs} {
        display: none;
      }

      &--mobile {
        display: none;

        @media #{variables.$md, variables.$xs} {
          display: flex;
        }
      }
    }
  }
}
