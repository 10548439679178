$imgix: 'https://ht-prod.imgix.net';
.spin {
  animation: spin 0.75s linear infinite;
  width: 1.75rem;
  height: 1.75rem;

  &--2xs {
    width: 1rem;
    height: 1rem;
  }

  &--xs {
    width: 1.25rem;
    height: 1.25rem;
  }

  &--sm {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--lg {
    width: 2rem;
    height: 2rem;
  }

  &--xl {
    width: 2.25rem;
    height: 2.25rem;
  }

  &--2xl {
    width: 2.5rem;
    height: 2.5rem;
  }

  &--4xl {
    width: 5rem;
    height: 5rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
