$imgix: 'https://ht-prod.imgix.net';
@use '../../styles/variables' as variables;

div.popover-content {
  position: relative;
  z-index: 50;
  outline: 2px solid transparent;
  outline-offset: 2px;

  &::after {
    z-index: 10;
  }
}
