/* color is kind of tricky with inline svg
   they are black by default
   filter: invert(1) makes them white
   use this page to find other filter colors:
     https://codepen.io/sosuke/pen/Pjoqqp
   e.g. this is red
    filter: invert(16%) sepia(88%) saturate(6462%) hue-rotate(4deg) brightness(104%) contrast(124%);
 */

@-webkit-keyframes iconSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes iconSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.svg-icon {
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;

  &.spin {
    -webkit-animation: iconSpin 1s linear 0s infinite;
    animation: iconSpin 1s linear 0s infinite;
  }
  &.icon-image-solid {
    background-image: url('./icons/solid/image.svg');
  }
  &.icon-house-user-regular {
    background-image: url('./icons/regular/house-user.svg');
  }
  &.icon-house-medical-circle-check-regular {
    background-image: url('./icons/regular/house-medical-circle-check.svg');
  }
  &.icon-house-medical-flag-regular {
    background-image: url('./icons/regular/house-medical-flag.svg');
  }
  &.icon-display-medical-regular {
    background-image: url('./icons/regular/display-medical.svg');
  }
  &.icon-question-circle {
    background-image: url('./icons/solid/question-circle.svg');
  }
  &.icon-bars {
    background-image: url('./icons/regular/bars.svg');
  }
  &.icon-search {
    background-image: url('./icons/solid/search.svg');
  }
  &.icon-regular-search {
    background-image: url('./icons/regular/search.svg');
  }
  &.icon-magnifying-glass-regular {
    background-image: url('./icons/regular/magnifying-glass.svg');
  }
  &.icon-magnifying-glass-02 {
    background-image: url('./icons/regular/magnifying-glass-02.svg');
  }
  &.icon-magnifying-glass-plus-02-regular {
    background-image: url('./icons/regular/magnifying-glass-plus-02.svg');
  }
  &.icon-filters {
    background-image: url('./icons/custom/filters.svg');
  }
  &.icon-circle-notch {
    background-image: url('./icons/solid/circle-notch.svg');
  }
  &.icon-circle-thin {
    background-image: url('./icons/thin/circle.svg');
  }
  &.icon-wrench {
    background-image: url('./icons/solid/wrench.svg');
  }
  &.icon-user-circle {
    background-image: url('./icons/solid/user-circle.svg');
  }
  &.icon-link-simple-regular {
    background-image: url('./icons/regular/link-simple.svg');
  }
  &.icon-facebook-f {
    background-image: url('./icons/brands/facebook-f.svg');
    filter: invert(0);
  }
  &.icon-twitter {
    background-image: url('./icons/brands/x-twitter.svg');
    filter: invert(1);
    background-position: center;
  }
  &.icon-reddit {
    background-image: url('./icons/brands/reddit.svg');
    filter: invert(0%) sepia(6%) saturate(7476%) hue-rotate(344deg) brightness(105%) contrast(105%);
  }
  &.icon-instagram {
    background-image: url('./icons/brands/instagram.svg');
    filter: invert(1);
    background-position: center;
  }
  &.icon-youtube {
    background-image: url('./icons/brands/youtube.svg');
    filter: invert(1);
    background-position: center;
  }
  &.icon-linkedin {
    background-image: url('./icons/brands/linkedin.svg');
    filter: invert(1);
    background-position: center;
  }
  &.icon-apple {
    background-image: url('./icons/brands/apple.svg');
  }
  &.icon-android {
    background-image: url('./icons/brands/android.svg');
  }
  &.icon-envelope {
    background-image: url('./icons/solid/envelope.svg');
    filter: invert(1);
  }
  &.icon-question-circle {
    background-image: url('./icons/solid/question-circle.svg');
  }
  &.icon-ad {
    background-image: url('./icons/solid/ad.svg');
  }
  &.icon-star-inactive {
    background-image: url('./icons/regular/star.svg');
  }
  &.icon-star-active {
    background-image: url('./icons/solid/star.svg');
  }
  &.icon-check-solid {
    background-image: url('./icons/solid/check.svg');
  }
  &.icon-check-circle {
    background-image: url('./icons/solid/check-circle.svg');
  }
  &.icon-check-circle-thin {
    background-image: url('./icons/custom/check-circle-thin.svg');
  }
  &.icon-play {
    background-image: url('./icons/solid/play.svg');
  }
  &.icon-pause {
    background-image: url('./icons/solid/pause.svg');
  }
  &.icon-play-circle {
    background-image: url('./icons/solid/play-circle.svg');
  }
  &.icon-pause-circle {
    background-image: url('./icons/solid/pause-circle.svg');
  }
  &.icon-sync-alt {
    background-image: url('./icons/solid/sync-alt.svg');
  }
  &.icon-triangle-6 {
    background-image: url('./icons/custom/triangle-6.svg');
    filter: invert(53%) sepia(79%) saturate(427%) hue-rotate(100deg) brightness(97%) contrast(88%);
  }
  &.icon-circle-plus {
    background-image: url('./icons/sharp-regular/circle-plus.svg');
  }
  &.icon-regular-plus {
    background-image: url('./icons/sharp-regular/plus.svg');
  }
  &.icon-circle-chevron-right-thin {
    background-image: url('./icons/thin/circle-chevron-right.svg');
  }
  &.icon-plus-solid {
    background-image: url('./icons/solid/plus.svg');
  }
  &.icon-minus-solid {
    background-image: url('./icons/solid/minus.svg');
  }
  &.icon-sliders-simple-regular {
    background-image: url('./icons/regular/sliders-simple.svg');
  }
  &.icon-chevron-down-solid {
    background-image: url('./icons/solid/chevron-down.svg');
  }
  &.icon-chevron-down-navy-solid {
    background-image: url('./icons/solid/chevron-down-navy.svg');
  }
  &.icon-chevron-right-solid {
    background-image: url('./icons/solid/chevron-right.svg');
  }
  &.icon-chevron-left-solid {
    background-image: url('./icons/solid/chevron-left.svg');
  }
  &.icon-arrow-right-solid {
    background-image: url('./icons/solid/arrow-right.svg');
  }
  &.icon-arrow-right-light {
    background-image: url('./icons/light/arrow-right.svg');
  }
  &.icon-arrow-down-solid {
    background-image: url('./icons/solid/arrow-down.svg');
  }
  &.icon-arrow-right-regular {
    background-image: url('./icons/regular/arrow-right.svg');
  }
  &.icon-arrow-right-navy-blue-regular {
    background-image: url('./icons/regular/arrow-right-navy-blue.svg');
    &.lg {
      width: 24px;
      height: 24px;
    }
    &.sm {
      width: 20px;
      height: 20px;
    }
  }
  &.icon-arrow-down-regular {
    background-image: url('./icons/regular/arrow-down.svg');
  }
  &.icon-xmark-solid {
    background-image: url('./icons/solid/xmark.svg');
  }
  &.icon-xmark-thin {
    background-image: url('./icons/thin/xmark.svg');
  }
  &.icon-xmark-regular {
    background-image: url('./icons/regular/xmark.svg');
  }
  &.icon-circle-x-solid {
    background-image: url('./icons/solid/circle-x.svg');
  }
  &.icon-circle-xmark-sharp {
    background-image: url('./icons/sharp-regular/circle-xmark.svg');
  }
  &.icon-circle-xmark-sharp-light {
    background-image: url('./icons/sharp-light/circle-xmark.svg');
  }
  &.icon-spinner-third-duotone {
    background-image: url('./icons/duotone/spinner-third.svg');
  }
  &.icon-headset-solid {
    background-image: url('./icons/solid/headset.svg');
  }
  &.icon-headset-solid {
    background-image: url('./icons/solid/headset.svg');
  }
  &.icon-battery-bolt-solid {
    background-image: url('./icons/solid/battery-bolt.svg');
  }
  &.icon-droplet-solid {
    background-image: url('./icons/solid/droplet.svg');
  }
  &.icon-sliders-light {
    background-image: url('./icons/light/sliders.svg');
  }
  &.icon-forward-step-solid {
    background-image: url('./icons/solid/forward-step.svg');
  }
  &.icon-circle-info-solid {
    background-image: url('./icons/solid/circle-info.svg');
  }
  &.icon-circle-info-light {
    background-image: url('./icons/light/circle-info.svg');
  }
  &.icon-circle-info-light-2 {
    background-image: url('./icons/light/circle-info-2.svg');
  }
  &.icon-waveform-solid {
    background-image: url('./icons/solid/waveform.svg');
  }
  &.icon-signal-duotone {
    background-image: url('./icons/duotone/signal.svg');
  }
  &.icon-signal-strong-duotone {
    background-image: url('./icons/duotone/signal-strong.svg');
  }
  &.icon-signal-good-duotone {
    background-image: url('./icons/duotone/signal-good.svg');
  }
  &.icon-signal-fair-duotone {
    background-image: url('./icons/duotone/signal-fair.svg');
  }
  &.icon-signal-weak-duotone {
    background-image: url('./icons/duotone/signal-weak.svg');
  }
  &.icon-lab-tested-logo-regular {
    background-image: url('./icons/regular/lab-tested-logo.svg');
  }
  &.icon-close-regular {
    background-image: url('./icons/regular/close.svg');
  }
  &.icon-grip-dots-regular {
    background-image: url('./icons/regular/grip-dots-vertical.svg');
    filter: invert(1);
  }
  &.icon-lock {
    background-image: url('./icons/regular/lock.svg');
    filter: invert(1);
  }
  &.icon-arrow-up-right-from-square {
    background-image: url('./icons/regular/arrow-up-right-from-square.svg');
  }
}
