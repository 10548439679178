$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

section.n4-hero {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-height: 75rem;
  margin-bottom: 5rem;
  position: relative;

  @media #{variables.$xs} {
    display: flex;
    overflow: hidden;
    border-radius: 1.25rem;
    gap: 3rem;
    margin-bottom: 3.75rem;
  }

  figure.image {
    position: relative;
    width: 100%;
    height: 45rem;
    display: flex;
    border-radius: 1.25rem;
    background-color: lightgray;
    overflow: hidden;
    margin: 0;

    @media #{variables.$xs} {
      max-height: 37.5rem;
    }

    img {
      object-fit: cover;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(72deg, rgba(0, 0, 0, 0.6) 23.25%, rgba(0, 0, 0, 0) 87.51%);
      z-index: 1;
    }

    > span {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
    }
  }

  section.content {
    border-radius: 1.25rem;
    overflow: hidden;
    position: absolute;
    display: flex;
    height: 45rem;
    width: 100%;
    max-width: 48rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2.36413rem 33rem 3.31rem 3rem;
    align-self: stretch;
    z-index: 2;

    @media #{variables.$xs} {
      display: flex;
      max-height: 37.5rem;
      height: 100%;
      width: 100%;
      max-width: 100%;
      padding: 1.5rem 1.125rem;
      flex-direction: column;
    }

    span.top-title {
      color: colors.$white;
      font-family: var(--hankenGrotesk-font);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: -0.01rem;
      max-width: 48rem;
    }

    section.content-data {
      color: colors.$white;
      font-family: var(--hankenGrotesk-font);
      display: flex;
      max-width: 48rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;

      @media #{variables.$xs} {
        height: 100%;
        display: flex;
        width: 22.5rem;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 1rem;
      }

      // Note: if the page doesn't have an article, the hero title is an h1 for SEO
      span.title,
      h1.title {
        margin-bottom: 0;
        font-size: 5rem;
        font-style: normal;
        font-weight: 550;
        line-height: 6rem;
        letter-spacing: -0.05rem;
        width: 34.25rem;

        @media #{variables.$xs} {
          color: colors.$white;
          font-family: var(--hankenGrotesk-font);
          font-size: 3rem;
          font-style: normal;
          font-weight: 550;
          line-height: 3.9rem;
          letter-spacing: -0.03rem;
          width: 100%;
        }
      }

      span.subtitle {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.015rem;
        opacity: 0.8;

        @media #{variables.$xs} {
          color: colors.$navy-blue-100;
          font-family: var(--hankenGrotesk-font);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.6875rem;
          letter-spacing: -0.01125rem;
        }
      }
    }
  }

  section.teaser {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding-bottom: 2.5rem;

    @media #{variables.$xs} {
      display: flex;
      width: 100%;
      max-width: none;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 0;
      padding-right: 3rem;
      gap: 2rem;
    }

    p.teaser-text {
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2.8rem;
      letter-spacing: -0.02rem;
      max-width: 53rem;
      padding-bottom: 0;
      margin-bottom: 0;

      @media #{variables.$xs} {
        width: 100%;
        max-width: none;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.1rem;
        letter-spacing: -0.015rem;
      }
    }
  }

  section.action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    span.text {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6875rem;
      letter-spacing: -0.01125rem;

      @media #{variables.$xs} {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: -0.01rem;
      }

      &--alt {
        color: colors.$navy-blue;
        cursor: pointer;
      }
    }

    button.jump {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: none;
      border-radius: 100%;
      background-color: colors.$white;
      width: 3.375rem;
      height: 3.375rem;

      i {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0.125rem;
        margin: auto;
        width: 1rem;
        height: 1.125rem;
        filter: invert(13%) sepia(47%) saturate(1050%) hue-rotate(183deg) brightness(90%) contrast(96%);
      }

      &--alt {
        border: 1px solid colors.$navy-blue;
        background-color: transparent;
      }
    }
  }
}
