$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/variables' as variables;
@use '../../../../styles/colors' as colors;

div.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  margin-top: 5rem;
}

section.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &--mobile {
    gap: 1rem;
    max-height: 40rem;
  }

  button.back {
    display: none;

    @media #{variables.$xs, variables.$md} {
      display: flex;
    }
  }

  section.items {
    &--column {
      margin: 0;
      padding: 0;
      max-height: 25.5rem;
      overflow-y: auto;
      display: grid;
      // grid fills data in by rows not columns, this allows data to fill in by columns
      grid-template-rows: 1fr 1fr 1fr;
      grid-auto-flow: column;
      justify-content: stretch;
      width: 100%;
      align-content: start;
      column-gap: 2rem;
      row-gap: 1.5rem;

      @media #{variables.$xs, variables.$md, variables.$lg} {
        // switch to fill in by row and scroll for sizes that don't fit more than three
        grid-template-rows: unset;
        grid-auto-flow: unset;
        width: unset;
        justify-content: center;
        grid-template-columns: minmax(0, 30rem);
        grid-auto-rows: minmax(7.5rem, auto);
      }

      @media #{variables.$xs, variables.$md} {
        grid-template-columns: unset;
        width: 100%;
        justify-content: flex-start;
        max-height: 100%;

        &--article-and-custom-link {
          row-gap: 1.5rem;
          grid-auto-rows: minmax(6rem, auto);
        }
      }
    }

    &--row {
      display: flex;
      gap: 1.5rem;
    }
  }

  section.tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 2rem;

    @media #{variables.$xs, variables.$md} {
      gap: unset;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
      align-self: stretch;
      max-width: fit-content;
      flex-wrap: wrap;

      @media #{variables.$xs, variables.$md} {
        padding: 2rem 0;
      }

      li {
        display: flex;

        button.tab {
          background: none;
          border: none;
          display: flex;
          padding: 0.5rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-radius: 3.75rem;
          border: 1px solid colors.$navy-blue-200;
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.225rem;
          letter-spacing: -0.02625rem;
          text-wrap: nowrap;

          &:hover,
          &--active {
            color: colors.$white;
            background: colors.$primary-blue;
          }
        }
      }
    }
  }
}
