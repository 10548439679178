$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;

section.n4-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 3rem;
  @media #{variables.$xs} {
    padding: 0 1.5rem 0.75rem;
  }

  @media #{variables.$sm} {
    max-width: 100%;
  }

  @media #{variables.$md} {
    //max-width: 720px;
  }

  @media #{variables.$lg} {
    // max-width: 960px;
  }

  @media #{variables.$laptop, variables.$desktop} {
    //max-width: 1440px;

    &--full-width {
      max-width: 100%;
    }
  }

  @media #{variables.$lg, variables.$laptop, variables.$desktop} {
    flex: 0 0 auto;
    width: 100%;
  }
}

section.n4-breadcrumbs-container {
  padding-top: 0;
  ol {
    li,
    li a {
      font-size: 0.9rem;
      font-weight: 300;
    }
  }
}

:global(.article-within-product) {
  section.n4-container {
    @media #{variables.$xs} {
      padding: 0 0.5rem 0.75rem;
    }
  }
}
