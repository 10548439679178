$imgix: 'https://ht-prod.imgix.net';
@use '../../styles/colors' as colors;

span.avatar {
  display: flex;
  overflow: hidden;
  place-content: center;
  place-items: center;
  background-color: lightgray;

  &--outline {
    outline-style: solid;
    outline-width: 2px;
    outline-color: colors.$navy-blue-200;
  }

  &--rounded {
    &-default {
      border-radius: 0.25rem;
    }

    &-full {
      border-radius: 100%;
    }

    &-lg {
      border-radius: 0.5rem;
    }

    &-md {
      border-radius: 0.375rem;
    }
  }

  &--size {
    &-md {
      min-width: 4rem;
      width: 4rem;
      height: 4rem;
    }

    &-sm {
      min-width: 3rem;
      width: 3rem;
      height: 3rem;
    }

    &-lg {
      min-width: 4.1875rem;
      width: 4.1875rem;
      height: 4.1875rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}

span.fallback {
  i {
    filter: invert(50%) sepia(20%) saturate(100%) hue-rotate(180deg) brightness(90%) contrast(100%);
    opacity: 0.3;
  }
}
