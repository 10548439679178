$imgix: 'https://ht-prod.imgix.net';
@use '../../styles/variables' as variables;
@use '../../styles/colors' as colors;

section.score {
  position: absolute;
  transform: scale(1.313);
  left: 0;
  top: 0;

  &--small {
    transform: scale(0.625);
    top: -10px;
    left: -10px;
  }

  &--right {
    left: unset;
    right: 0;

    &-small {
      right: -10px;
    }
  }

  svg {
    width: 4.5rem;
    height: 4.5rem;
  }

  span.amount {
    top: 1.625rem;
    right: 0.063rem;
    left: 0;
    bottom: 0;
    position: absolute;
    color: colors.$navy-blue;
    font-family: var(--hankenGrotesk-font);
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.8rem;
    letter-spacing: -0.015rem;
  }

  div.logo-text {
    width: 100%;
    top: 0.5rem;
    right: 0.313rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    div.logo {
      display: flex;
      align-items: center;
      background-image: url('/images/logo-ear-only-2.svg');
      background-repeat: no-repeat;
      width: 20px;
      height: 28px;
      transform: scale(0.25);
    }

    span {
      text-align: center;
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 0.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.45913rem;
      letter-spacing: -0.00456rem;
      margin-left: -0.375rem;
    }
  }
}
