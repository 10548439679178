$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/variables' as variables;
@use '../../../../styles/colors' as colors;

section.nav-search {
  display: contents;

  div.form-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    section.form {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 2rem 0;

      @media #{variables.$xs, variables.$md} {
        padding: 1.5rem 1rem;
      }

      @media #{variables.$lg} {
        padding: 2rem 3rem;
      }

      @media #{variables.$laptop} {
        padding: 2rem 3rem;
        left: -7px;
      }

      @media #{variables.$desktop} {
        max-width: 1328px;
        left: -7px;
      }

      @media #{variables.$desktop-to-wide, variables.$desktop-wide} {
        max-width: 1355px;
        left: -1px;
      }

      input {
        padding: 0;
        margin: 0;
        border: 0;
        display: flex;
        padding-bottom: 0.75rem;
        padding-left: 3.5rem;
        min-height: 3.75rem;
        justify-content: space-between;
        align-items: flex-end;
        align-self: stretch;
        flex: 1 0 0;
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.4rem;
        letter-spacing: -0.06rem;
        border-bottom: 1px solid colors.$navy-blue-100;

        @media #{variables.$xs, variables.$md} {
          padding-bottom: 0rem;
          padding-left: 3.5rem;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.75rem;
          letter-spacing: -0.0375rem;
        }

        &:focus,
        &:active {
          outline: none;
          border-bottom: 1px solid colors.$navy-blue;
        }

        &::placeholder {
          font-weight: 400;
          color: colors.$navy-blue-200;
          padding-bottom: 0.75rem;
          border-bottom: 0;
        }

        &::-webkit-input-placeholder {
          font-weight: 400;
          color: colors.$navy-blue-200;
          padding-bottom: 0.75rem;
        }

        &:-moz-placeholder {
          font-weight: 400;
          color: colors.$navy-blue-200;
          padding-bottom: 0.75rem;
        }
      }

      div {
        background: none;
        border: none;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        background: #f4f4f4;
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
        z-index: 4;
        //left: 3rem;
        top: 45%;

        @media #{variables.$xs, variables.$md} {
          left: 0.8rem;
          top: 50%;
        }

        i.magnifying-glass {
          min-width: 1.25rem;
          min-height: 1.25rem;
          filter: invert(9%) sepia(29%) saturate(3922%) hue-rotate(205deg) brightness(94%) contrast(91%);

          @media #{variables.$xs} {
            min-width: 1.5rem;
            min-height: 1.5rem;
          }
        }
      }
    }
  }

  section.result {
    display: flex;
    max-height: calc(100vh - 18rem);
    width: 100%;
    padding: 2rem 3rem;
    padding-top: 0;
    overflow-y: auto;
    min-width: 100%;
    width: 100%;
    align-self: stretch;

    @media #{variables.$xs,variables.$md, variables.$lg} {
      max-height: 100%;
      height: fit-content;
      margin-bottom: 2rem;
    }

    @media #{variables.$xs, variables.$md} {
      padding: 1.5rem 1rem;
      margin-bottom: 1.5rem;
    }

    section.initial {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 2.1rem;
      align-self: stretch;
      min-width: 100%;
      width: 100%;
      @media #{variables.$xs, variables.$md} {
        flex-direction: column;
        gap: 1.5rem;
      }
    }

    section.data {
      display: flex;
      width: 100%;
      height: 40rem;
      gap: 3rem;
      max-height: 42rem;
      justify-content: space-between;
      align-items: flex-start;

      @media #{variables.$xs, variables.$md, variables.$lg} {
        flex-direction: column;
      }

      @media #{variables.$xs, variables.$md} {
        max-height: 44.563rem;
        height: 44.563rem;
      }

      @media #{variables.$xs} {
        height: auto;
        max-height: none;
      }

      section.articles {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;

        @media #{variables.$xs, variables.$md, variables.$lg} {
          max-width: 100%;
        }

        section.articles-top {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
          align-self: stretch;

          &--left {
            display: flex;
            width: 100%;
            max-width: 13.5rem;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 0.25rem;

            h4 {
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.8rem;
              letter-spacing: -0.045rem;
              text-wrap: nowrap;

              @media #{variables.$xs, variables.$md} {
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.4625rem;
                letter-spacing: -0.03375rem;
              }
            }

            a span {
              color: colors.$primary-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.225rem;
              letter-spacing: -0.02625rem;

              &:hover,
              &:active {
                color: colors.$navy-blue;
              }
            }
          }
        }

        section.articles-bottom {
          display: contents;

          section.articles-data {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;

            @media #{variables.$xs, variables.$md, variables.$lg} {
              max-width: 100%;
            }

            @media #{variables.$desktop, variables.$desktop-to-wide, variables.$desktop-wide} {
              display: grid;
              width: 100%;
              max-width: 68.25rem;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              grid-auto-rows: minmax(7.5rem, auto);
              gap: 2.5rem 1.5rem;
              top: 0;
              padding-top: 1rem;
            }
          }

          div.no-results {
            display: flex;
            width: 100%;
            height: 24rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            @media #{variables.$xs, variables.$md, variables.$lg} {
              max-width: 100%;
            }

            @media #{variables.$xs, variables.$md} {
              max-height: 100%;
              height: fit-content;
              padding: 5rem 0 4rem 0;
              margin-bottom: 5rem;
            }

            section.content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 1.25rem;

              section.no-results-details {
                display: flex;
                flex-direction: column;
                justify-content: center !important;
                align-items: center;
                gap: 0.25rem;

                p {
                  padding: 0;
                  margin: 0;
                  color: colors.$navy-blue;
                  font-family: var(--hankenGrotesk-font);
                  text-align: center;
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.4625rem;
                  letter-spacing: -0.03375rem;
                }

                span {
                  color: colors.$navy-blue-800;
                  font-family: var(--hankenGrotesk-font);
                  text-align: center;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 1.5rem;
                  letter-spacing: -0.03rem;
                }
              }

              a {
                height: auto;
                padding: 0.5rem 1rem;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 300;
                line-height: 1.225rem;
                letter-spacing: -0.02625rem;
              }
            }
          }

          section.article {
            display: flex;
            width: 100%;
            min-height: 9.5rem;
            padding: 1rem 0rem;
            justify-content: space-between;
            align-items: flex-end;
            gap: 1rem;
            border-bottom: 1px solid colors.$navy-blue-200;

            @media #{variables.$desktop, variables.$desktop-to-wide, variables.$desktop-wide} {
              max-width: 33.125rem;
              min-height: 7.5rem;
              max-height: 7.5rem;
              border-bottom: none;
            }

            &--left {
              display: flex;
              width: 100%;
              max-width: 37.5rem;
              align-items: flex-start;
              gap: 1.5rem;
              flex-shrink: 0;

              @media #{variables.$xs, variables.$md, variables.$lg, variables.$desktop, variables.$desktop-to-wide, variables.$desktop-wide} {
                flex-shrink: unset;
                max-width: 100%;
              }

              figure {
                margin: 0;
                position: relative;
                display: flex;
                justify-content: center;
                overflow: hidden;
                aspect-ratio: 160/120;
                border-radius: 0.813rem;
                min-width: 10rem;
                max-width: 10rem;
                min-height: 7.5rem;
                max-height: 7.5rem;

                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }

                div.placeholder {
                  min-width: 10rem;
                  min-height: 7.5rem;
                  border-radius: 0.813rem;
                  background-color: colors.$navy-blue-200;
                }
              }

              section.details {
                display: flex;
                max-width: 27.5rem;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.375rem;
                flex-shrink: 0;

                @media #{variables.$xs, variables.$md, variables.$lg, variables.$laptop, variables.$desktop, variables.$desktop-to-wide, variables.$desktop-wide} {
                  flex-shrink: unset;
                  max-width: 100%;
                }

                h5,
                h5 p {
                  padding: 0;
                  margin: 0;
                  color: colors.$navy-blue;
                  font-family: var(--hankenGrotesk-font);
                  font-size: 1.25rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.5rem;
                  letter-spacing: -0.0375rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;

                  em {
                    font-weight: 700;
                    font-style: normal;
                  }

                  @media #{variables.$xs, variables.$md} {
                    font-size: 1rem;
                    font-style: normal;
                    line-height: 1.4rem;
                    letter-spacing: -0.03rem;
                  }
                }

                p {
                  padding: 0;
                  margin: 0;
                  color: colors.$navy-blue-600;
                  font-family: var(--hankenGrotesk-font);
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 1.225rem;
                  letter-spacing: -0.02625rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;

                  em {
                    font-weight: 700;
                    font-style: normal;
                    color: colors.$navy-blue-800;
                  }

                  @media #{variables.$xs, variables.$md} {
                    em {
                      font-weight: 600;
                    }
                  }
                }
              }
            }

            &--right {
              position: relative;
              display: flex;
              align-items: center;
              gap: 1rem;
              min-width: 8.625rem;

              @media #{variables.$xs, variables.$md, variables.$lg, variables.$laptop, variables.$desktop, variables.$desktop-to-wide, variables.$desktop-wide} {
                display: none;
              }

              span {
                display: flex;
                align-self: center;
                align-items: center;
                color: colors.$navy-blue;
                font-family: var(--hankenGrotesk-font);
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.4rem;
                letter-spacing: -0.03rem;
              }

              div.icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                width: 3.5rem;
                height: 3.5rem;
                border: 0.857px solid colors.$navy-blue-200;
                width: 3rem;
                height: 3rem;

                i {
                  position: absolute;
                  top: 0.125rem;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  margin: auto;
                  width: 1rem;
                  height: 1.188rem;
                  filter: invert(13%) sepia(47%) saturate(1050%) hue-rotate(183deg) brightness(90%) contrast(96%);
                }
              }
            }
          }
        }
      }

      section.products {
        display: flex;
        width: 100%;
        max-width: 27.5rem;
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        border-radius: 1.5625rem;
        background: colors.$navy-blue-50;

        @media #{variables.$xs, variables.$md, variables.$lg} {
          max-width: 100%;
        }

        @media #{variables.$xs, variables.$md} {
          padding: 1.25rem;
        }

        section.products-top {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
          align-self: stretch;
          padding: 7px 0 0 6px;
          &--left {
            display: flex;
            width: 100%;
            max-width: 13.5rem;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 0.25rem;

            h4 {
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.8rem;
              letter-spacing: -0.045rem;
              text-wrap: nowrap;

              @media #{variables.$xs} {
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.4625rem;
                letter-spacing: -0.03375rem;
              }
            }

            a span {
              color: colors.$primary-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.225rem;
              letter-spacing: -0.02625rem;

              &:hover,
              &:active {
                color: colors.$navy-blue;
              }
            }
          }
        }

        section.products-bottom {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.5rem;
          width: 100%;

          div.no-results {
            display: flex;
            max-width: 25.1875rem;
            width: 100%;
            min-height: 23.25rem;
            padding: 6rem 0rem;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;
            flex: 1 0 0;

            @media #{variables.$xs, variables.$md, variables.$lg} {
              max-width: 100%;
            }

            @media #{variables.$xs, variables.$md} {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 1.25rem;
              flex: 1 0 0;
              min-height: 100%;
              padding: 5rem 0 4rem 0;
            }

            section.content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 1.25rem;

              @media #{variables.$xs, variables.$md} {
                flex: 1 0 0;
              }

              section.no-results-details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.25rem;

                p {
                  padding: 0;
                  margin: 0;
                  color: colors.$navy-blue;
                  font-family: var(--hankenGrotesk-font);
                  text-align: center;
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.4625rem;
                  letter-spacing: -0.03375rem;
                }

                span {
                  color: colors.$navy-blue-800;
                  font-family: var(--hankenGrotesk-font);
                  text-align: center;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 1.5rem;
                  letter-spacing: -0.03rem;
                }
              }

              a {
                height: auto;
                padding: 0.5rem 1rem;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 300;
                line-height: 1.225rem;
                letter-spacing: -0.02625rem;
              }
            }
          }

          section.product {
            display: flex;
            width: 100%;
            max-width: 25.1875rem;
            align-items: flex-start;
            gap: 1.1875rem;

            @media #{variables.$xs, variables.$md, variables.$lg} {
              max-width: 100%;
            }

            @media #{variables.$xs, variables.$md} {
              max-height: 7.3125rem;
              gap: 1.25rem;
            }

            figure {
              background: white;
              margin: 0;
              position: relative;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              align-content: center;
              overflow: hidden;
              aspect-ratio: 160/120;
              border-radius: 0.813rem;
              min-width: 9rem;
              max-width: 9rem;
              min-height: 6.7rem;
              max-height: 6.7rem;
              border: 1px solid #d4d4d4;

              @media #{variables.$xs, variables.$md} {
                aspect-ratio: 88/69;
                min-width: 5.5rem;
                max-width: 10rem;
                min-height: 4.3125rem;
                max-height: 12rem;
              }

              img {
                object-fit: fill;
                width: auto;
                height: 80%;
              }

              div.placeholder {
                min-width: 9rem;
                min-height: 6.9rem;
                border-radius: 0.813rem;
                background-color: colors.$navy-blue-200;
              }
            }

            section.details {
              display: flex;
              width: 100%;
              max-width: 14rem;
              flex-direction: column;
              align-items: flex-start;
              gap: 0.375rem;
              flex-shrink: 0;

              @media #{variables.$xs, variables.$md, variables.$lg} {
                max-width: 100%;
                flex-shrink: unset;
              }

              h5,
              h5 p {
                padding: 0;
                margin: 0;
                color: colors.$navy-blue;
                font-family: var(--hankenGrotesk-font);
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: -0.0375rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                em {
                  font-weight: 700;
                  font-style: normal;
                }

                @media #{variables.$xs} {
                  -webkit-line-clamp: 1;
                  font-size: 1.125rem;
                }
              }

              div.price {
                display: flex;
                color: colors.$primary-blue;
                font-family: var(--hankenGrotesk-font);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.225rem;
                letter-spacing: -0.02625rem;
              }
              p {
                padding: 0;
                margin: 0;
                color: colors.$navy-blue-600;
                font-family: var(--hankenGrotesk-font);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.225rem;
                letter-spacing: -0.02625rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                em {
                  font-weight: 700;
                  font-style: normal;
                  color: colors.$navy-blue-800;
                }

                @media #{variables.$xs, variables.$md} {
                  -webkit-line-clamp: 2;
                  em {
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
