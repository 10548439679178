$imgix: 'https://ht-prod.imgix.net';
@use '../../styles/variables' as variables;
@use '../../styles/colors' as colors;

.score {
  aspect-ratio: 1/1;

  &.score--xs {
    min-width: 34px;
  }

  &.score--sm {
    min-width: 48px;
  }

  &.score--mid {
    min-width: 66px;
  }

  &.score--md {
    min-width: 72px;
  }

  &.score--lg {
    min-width: 100px;
  }

  &.score--xl {
    min-width: 144px;
  }

  &.score--2xl {
    width: 236px;
  }

  > svg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }

  div.info {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    container: scoreinfo / size;

    .amount {
      font-weight: 600;
      font-style: normal;
      line-height: 1.2em;
      color: colors.$navy-blue;
      letter-spacing: -0.015rem;
      font-family: var(--hankenGrotesk-font);
      z-index: 0;
    }

    div.logo-text {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;

      span {
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.00456rem;
        line-height: 1;
      }
    }

    // The below is a mess, but it seems to look at the width of the "info" container that
    // stores the logo, "score" and actual score amount. Importantly, it seems to be based
    // on the desktop width rather than the mobile width, so min and max width are not aware
    // of the width of the info container on when it has been resized on mobile. Hmm, maybe
    // I don't understand this after all because in some places, like the big score in the
    // HT Score panel on product page, the score wheel is affected by the next lower
    // breakpoint. This is inconsistent with the comparison directory wheels.

    // seen on pages: sidebar
    @container scoreinfo (min-width: 34px) and (max-width: 47px) {
      .amount {
        font-size: 0.85rem;
        line-height: 0.9rem;
      }

      svg {
        width: 4px;
        height: 4px;
      }

      div.logo-text span {
        font-size: 3px;
      }
    }

    @container scoreinfo (min-width: 48px) and (max-width: 50px) {
      div.logo-text {
        gap: 1px;
        margin-top: 2px;
      }
      .amount {
        font-size: 1rem;
        line-height: 1.2rem;
      }

      svg {
        width: 5px;
        height: 6px;
      }

      div.logo-text span {
        font-size: 5px;
      }
    }

    // seen on pages: home page featured reviews on mobile
    @container scoreinfo (min-width: 51px) and (max-width: 65px) {
      div.logo-text {
        gap: 1px;
        margin-top: 2px;
      }
      .amount {
        font-size: 1.3rem;
        line-height: 1.5rem;
      }

      svg {
        width: 5px;
        height: 6px;
      }

      div.logo-text span {
        font-size: 5px;
      }
    }

    // seen on pages: Product page mobile view
    @container scoreinfo (min-width: 66px) and (max-width: 71px) {
      div.logo-text {
        gap: 1px;
        margin-top: 1px;
      }
      .amount {
        font-size: 1.65rem;
        line-height: 1.75rem;
      }

      svg {
        width: 5px;
        height: 6px;
      }

      div.logo-text span {
        font-size: 7px;
      }
    }

    // seen on pages: directory, product card and product card popup, also featured on home page
    // and top featured review aid on mobile
    @container scoreinfo (min-width: 72px) and (max-width: 80px) {
      .amount {
        font-size: 1.6rem;
        line-height: 1.8rem;
        @media #{variables.$xs} {
          font-size: 1.8em;
          line-height: 2.1rem;
        }
      }
      div.logo-text {
        svg {
          width: 8px;
          height: 8px;
        }
        margin-top: 0.2rem;
      }

      div.logo-text span {
        font-size: 0.5rem;
        @media #{variables.$xs} {
          font-size: 0.55rem;
        }
      }
    }

    // seen on pages: none found
    // @container scoreinfo (min-width: 78px) and (max-width: 99px) {
    // }

    // seen on pages: product page sticky score card
    @container scoreinfo (min-width: 100px) and (max-width: 119px) {
      div.logo-text {
        margin-top: -2px;
      }
      .amount {
        font-size: 2.3rem;
        line-height: 2.3rem;
      }

      div.logo-text .logo {
        width: 7px;
        height: 9px;
      }

      div.logo-text span {
        font-size: 0.6em;
      }
    }

    // seen on pages: home page, top featured review aid on desktop
    @container scoreinfo (min-width: 120px) and (max-width: 159px) {
      div.logo-text {
        margin-top: -2px;
        gap: 3px;
      }
      .amount {
        font-size: 2.8rem;
        line-height: 2.9rem;
      }

      div.logo-text .logo {
        width: 8px;
        height: 10px;
      }

      div.logo-text span {
        font-size: 0.7em;
      }
    }

    // seen on pages: Product page large score wheel on mobile
    @container scoreinfo (min-width: 160px) and (max-width: 199px) {
      .amount {
        font-size: 4em;
        line-height: 4.8rem;
      }

      div.logo-text {
        gap: 3px;

        .logo {
          width: 12px;
          height: 14px;
        }
      }

      div.logo-text span {
        font-size: 1rem;
      }
    }
    // seen on pages: Product page large score wheel on desktop
    @container scoreinfo (min-width: 200px) {
      .amount {
        font-size: 5.4rem;
        line-height: 6rem;
      }

      div.logo-text {
        gap: 5px;

        .logo {
          width: 13px;
          height: 17px;
        }
      }

      div.logo-text span {
        font-size: 1.15rem;
      }
    }
  }
}

.home-product-scorecard {
  position: absolute;
  inset: 12px auto auto 12px;
  width: 60px;
  aspect-ratio: 1;
  --tw-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.15);
  --tw-shadow-colored: 1px 2px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  @media #{variables.$desktop-wide} {
    inset: 15px auto auto 15px;
    width: 78px;
  }
}
