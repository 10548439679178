$imgix: 'https://ht-prod.imgix.net';
div.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ht-faq {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  margin-bottom: 40px;
  h5 {
    padding-right: 10px;
  }
  h2,
  h3,
  h4 {
    font-weight: 500 !important;
    margin: auto;
    max-width: 640px;
    margin-bottom: 1.2rem !important;
  }
}
