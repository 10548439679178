$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;
@use '../../../../../styles/mixins' as functionsAndMixins;

section.accordion {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  max-width: 810px;

  section.item {
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: black;
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 8px 0px rgba($color: black, $alpha: 0.05);

    @media #{variables.$xs} {
      border-radius: 10px;
    }

    section.header {
      width: 100%;

      h5 {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;

        button.button {
          position: relative;
          display: flex;
          align-items: center;
          text-align: start;
          justify-content: space-between;
          width: 100%;
          border: none;
          border-radius: 20px;
          width: 100%;
          color: colors.$navy-blue;
          font-size: 20px;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: -0.6px;
          min-height: 40px;

          transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }
      }
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: auto;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .icon {
      min-width: 1.25rem;
      min-height: 1.25rem;
      @include functionsAndMixins.rotate(0.3s);
    }

    section.body {
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;

      section.content {
        margin-bottom: 8px;
        padding-top: 1rem;
        color: rgba($color: colors.$navy-blue, $alpha: 0.9);
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.48px;
        ul li,
        ol li {
          font-weight: 300;
          line-height: 1.7;
          margin-bottom: 0.5rem;

          @media #{variables.$xs} {
            font-weight: 400;
          }
        }
        ul,
        ol {
          margin: 1.5rem 1rem 1.5rem 1.6rem;
          li {
            padding-left: 0.3rem;
          }
        }
        ul li {
          font-size: 1.3rem; // same as parent
          list-style-type: disc;
        }
        ol li {
          list-style-type: decimal;
        }
      }
    }
  }
}
