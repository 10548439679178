$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

section.table {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3rem 0 3rem;
  max-width: 810px;

  @media #{variables.$xs} {
    width: 100%;
  }
  @media #{variables.$sm} {
    width: auto;
  }
  h3 {
    color: colors.$navy-blue;
    font-family: var(--hankenGrotesk-font);
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4rem;
    letter-spacing: -0.02rem;
  }

  div.wrapper {
    position: relative;
    width: fit-content;
    max-width: 100%;

    @media #{variables.$xs} {
      width: 100%;
    }

    p.captions {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: fit-content;
      min-height: 1.3125rem;
      border-left: 2px solid colors.$navy-blue;
      padding-left: 0.5rem;
      margin-top: 20px;

      @media #{variables.$md} {
        max-width: 75vw;
      }

      @media #{variables.$xs} {
        max-width: 100%;
        width: 100%;
      }

      span {
        width: 100%;
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        @media #{variables.$xs} {
          font-weight: 350;
          font-size: 1.15rem;
        }
      }
    }

    div.container {
      width: fit-content;
      max-width: 100%;
      overflow-x: auto;
      margin: 0 auto;
      border-radius: 1.25rem;
      box-shadow: 4px 12px 40px 0px rgba(0, 0, 0, 0.1);

      @media #{variables.$xs, variables.$md} {
        max-width: calc(100vw - 35px);
        width: 100%;
        margin: 0;
      }

      table {
        background: colors.$white;
        border-collapse: initial;
        border-spacing: 0;
        padding: 1.5rem;
        @media #{variables.$xs} {
          width: 100%;
        }
        @media #{variables.$sm} {
          width: auto;
        }
        thead,
        tbody {
          tr {
            th,
            td {
              padding: 0.5rem 1.5rem;
              white-space: nowrap;
            }
          }
        }

        thead {
          tr {
            th {
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 1.25rem;
              font-style: normal;
              font-weight: 600;
              line-height: 1.5rem;
              letter-spacing: -0.0125rem;
              padding-bottom: 2rem;
              text-align: left;
            }
          }
        }

        tbody {
          tr {
            td {
              border-top: 1px solid colors.$navy-blue-100;
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 1.313rem;
              font-style: normal;
              font-weight: 400;
              line-height: 2.6rem;
              letter-spacing: -0.013rem;

              &:first-child {
                color: colors.$navy-blue;
                font-family: var(--hankenGrotesk-font);
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5rem;
                letter-spacing: -0.0125rem;
              }
            }

            &:first-child {
              td {
                padding-top: 1.5rem;
                border-top: 2px solid colors.$navy-blue;
              }
            }
          }
        }
      }
    }
  }
}

:global(.article-within-product) {
  section.table {
    max-width: 100%;
  }
}
