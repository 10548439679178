$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/variables' as variables;
@use '../../../../styles/colors' as colors;

section.wrapper {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  justify-content: center;
  background-color: colors.$white;
  min-width: 100vw;
  overflow-y: auto;
  margin-top: -14px;
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 68px);
}

section.panel-container {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  background-color: colors.$white;
  border-radius: 0.625rem;
  height: fit-content;
  max-height: 34.25rem;
  padding: 2.5rem 3rem;
  align-items: flex-start;
  gap: 3rem;

  @media #{variables.$xs} {
    width: 100%;
    min-width: calc(100vw - 10px);
    max-width: calc(100vw - 10px);
  }

  @media #{variables.$sm} {
    min-width: 498px;
    max-width: 498px;
  }

  @media #{variables.$md} {
    min-width: 624px;
    max-width: 624px;
  }

  @media #{variables.$lg} {
    min-width: 864px;
    max-width: 864px;
  }

  @media #{variables.$laptop} {
    width: 1200px;
    max-width: 1440px;
  }

  @media #{variables.$desktop} {
    width: 1440px;
    max-width: 1440px;
  }

  @media #{variables.$sm, variables.$md, variables.$lg} {
    padding-right: 0;
    padding-left: 0;
  }

  section.options {
    display: flex;
    min-width: 18.25rem;
    padding-right: 2.5rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    border-right: 1px solid colors.$navy-blue-200;

    section.menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      align-self: stretch;

      span {
        color: colors.$primary-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.225rem;
        letter-spacing: -0.02625rem;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        align-self: stretch;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          padding: 0;
          margin: 0;

          button.option {
            padding: 0;
            margin: 0;
            background: transparent;
            border: none;
            display: flex;
            color: colors.$navy-blue;
            font-family: var(--hankenGrotesk-font);
            font-size: 1.3rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.6rem;
            letter-spacing: -0.039rem;
            opacity: 0.4;

            &:hover,
            &--active {
              opacity: 1;
            }
          }
        }
      }
    }

    section.all-options {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;

      a {
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4rem;
        letter-spacing: -0.03rem;
      }

      a.option-bottom {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: none;
        border-radius: 100%;
        background-color: colors.$white;
        width: 3rem;
        height: 3rem;
        border: 0.938px solid colors.$navy-blue-400;

        i {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0.125rem;
          margin: auto;
          width: 1.063rem;
          height: 1.063rem;
          flex-shrink: 0;
          filter: invert(12%) sepia(27%) saturate(1946%) hue-rotate(185deg) brightness(98%) contrast(95%);
        }
      }
    }
  }

  section.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    div.loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 100%;
    }
  }
}

section.mobile {
  display: contents;
}

section.panel-container {
  &--mobile {
    display: flex;
    max-height: 100%;
    padding: 1.5rem 1rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 0.9375rem;
    min-height: 32.5rem;
    align-self: stretch;

    section.content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      button {
        padding: 0;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        align-self: stretch;
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4rem;
        letter-spacing: -0.03rem;

        i {
          width: 0.875rem;
          height: 0.875rem;
          filter: invert(9%) sepia(29%) saturate(3922%) hue-rotate(205deg) brightness(94%) contrast(91%);
        }
      }

      ul.menu {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;

        li {
          display: flex;
          width: 100%;

          button.option {
            background: none;
            border: none;
            display: flex;
            width: 100%;
            justify-content: space-between;
            color: colors.$navy-blue;
            font-family: var(--hankenGrotesk-font);
            align-items: center;
            align-self: stretch;
            color: colors.$navy-blue;
            font-family: var(--hankenGrotesk-font);
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2.4rem;
            letter-spacing: -0.06rem;
            opacity: 1;

            i {
              width: 0.675rem;
              height: 1rem;
              filter: invert(9%) sepia(29%) saturate(3922%) hue-rotate(205deg) brightness(94%) contrast(91%);
            }
          }
        }
      }
    }

    section.all-options {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;

      a {
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4rem;
        letter-spacing: -0.03rem;
      }

      a.option-bottom {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: none;
        border-radius: 100%;
        background-color: colors.$white;
        width: 3rem;
        height: 3rem;
        border: 0.938px solid colors.$navy-blue-400;

        i {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0.125rem;
          margin: auto;
          width: 1.063rem;
          height: 1.063rem;
          flex-shrink: 0;
          filter: invert(12%) sepia(27%) saturate(1946%) hue-rotate(185deg) brightness(98%) contrast(95%);
        }
      }
    }
  }
}

section.panel-container {
  &--search {
    padding: 0;
    min-height: 26.5625rem;
    max-height: 49.75rem;

    @media #{variables.$xs, variables.$md, variables.$lg, variables.$laptop} {
      min-height: fit-content;
      max-height: fit-content;
    }

    @media #{variables.$lg, variables.$laptop, variables.$desktop} {
      width: 1640px;
      max-width: calc(100vw - 1rem);
    }

    @media #{variables.$xs} {
      max-height: calc(100dvh - 68px);
      overflow-y: auto;
    }

    section.content {
      display: flex;
      align-items: flex-start;
    }
  }
}
