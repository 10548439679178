$imgix: 'https://ht-prod.imgix.net';
// To avoid any potential issue with character encoding
@charset 'utf-8';

@use './colors.scss';
@import './variables';
@import './mixins';
@import './bootstrap_reboot';
@import './icons';
@import './theme';
@import './react_carousel';

* {
  box-sizing: border-box;
}

// for analytics click tracking so the child element doesn't get the event
a[data-analytics-label] * {
  pointer-events: none;
}
a[data-track] * {
  pointer-events: none;
}
