$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/colors' as colors;
@use '../../../styles/variables' as variables;

section.n4-article-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: stretch;

  @media #{variables.$xs} {
    gap: 2rem;
  }

  span.grid-title {
    color: colors.$black;
    font-family: var(--hankenGrotesk-font);
    font-size: 2rem;
    font-style: normal;
    font-weight: 450;
    line-height: 2.4rem;
    letter-spacing: -0.02rem;
    margin-bottom: 2rem;

    @media #{variables.$xs} {
      width: 100%;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 450;
      line-height: 2.275rem;
      letter-spacing: -0.0175rem;
      margin-bottom: 0;
    }
  }

  section.article-content {
    position: relative;
    display: flex;
    flex-direction: column;

    section.cards {
      display: grid;
      align-items: stretch;
      width: 100%;
      height: fit-content;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem;

      @media #{variables.$md, variables.$lg} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media #{variables.$laptop, variables.$desktop} {
        grid-template-columns: repeat(3, 1fr);
      }

      article.card {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        flex: 1 0 0;
        z-index: 1;
      }

      figure.image {
        position: relative;
        border-radius: 1.25rem;
        overflow: hidden;
        aspect-ratio: 426/300;
        margin: 0;

        @media #{variables.$xs} {
          aspect-ratio: 396/280;
        }

        img {
          border-radius: 1.25rem;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        div.icon {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          z-index: 2;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: none;
          border-radius: 100%;
          background-color: colors.$white;
          box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15);
          width: 3.375rem;
          height: 3.375rem;

          i {
            width: 1rem;
            height: 1.188rem;
            filter: invert(13%) sepia(47%) saturate(1050%) hue-rotate(183deg) brightness(90%) contrast(96%);
          }
        }

        span {
          position: absolute;
          top: 1rem;
          left: 1rem;
          display: flex;
          padding: 0.5rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          border-radius: 3.4375rem;
          background: colors.$sky-50;
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.4rem;
          letter-spacing: -0.01rem;
        }
      }

      section.card-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        align-self: stretch;

        @media #{variables.$xs} {
          gap: 1rem;
          min-height: 8rem;
          max-height: 8rem;

          &--carousel {
            min-height: 10rem;
            max-height: 12rem;
          }
        }

        span {
          color: colors.$black;
          font-family: var(--hankenGrotesk-font);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.4rem;
          letter-spacing: -0.01rem;
          min-height: 1.438rem;

          @media #{variables.$xs} {
            color: colors.$navy-blue;
          }
        }

        h3 {
          color: colors.$black;
          font-family: var(--hankenGrotesk-font);
          font-size: 1.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: -0.0175rem;
          overflow: hidden;
          margin: 0;

          @media #{variables.$xs} {
            color: colors.$navy-blue;
            font-style: normal;
            font-weight: 500;
            line-height: 1.3;
            letter-spacing: -0.015rem;
          }
        }
      }
    }

    ul.carousel-indicators {
      @media #{variables.$xs} {
        margin-right: 0;
        margin-left: 0;

        li {
          width: 100%;
          margin-right: 0.188rem;
          margin-left: 0.188rem;

          button {
            width: 100%;
            border: none;
            background-color: colors.$navy-blue;
            border-radius: 12px;
            height: 6px;
          }
        }
      }
    }
  }

  section.action {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    gap: 1rem;

    @media #{variables.$xs} {
      margin-top: 0;
    }

    span.text {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 350;
      line-height: 1.6875rem;
      letter-spacing: -0.01125rem;
      color: colors.$navy-blue;
      cursor: pointer;
    }

    button.show {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: none;
      border-radius: 100%;
      background-color: transparent;
      border: 0.889px solid colors.$navy-blue-200;
      width: 3rem;
      height: 3rem;

      i {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0.25rem;
        margin: auto;
        width: 1rem;
        height: 1rem;
        filter: invert(13%) sepia(47%) saturate(1050%) hue-rotate(183deg) brightness(90%) contrast(96%);
      }
    }
  }
}
