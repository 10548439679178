@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform;
  }
}

.n4-article + .n4-call-to-action {
  margin-top: 3rem;
}

.n4-call-to-action + .n4-article-grid {
  margin-top: 5rem;
}

.n4-image + .n4-pros-and-cons {
  margin-top: 0;
}

.n4-product-card + .n4-pros-and-cons,
.n4-pros-and-cons + .ht-youtube,
.ht-youtube + .n4-pros-and-cons,
.n4-pros-and-cons + .n4-image,
.ht-youtube + .ht-faq,
.brand-releases + .ht-faq,
.ht-youtube + .brand-releases,
.n4-image + .brand-releases {
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}

.mkdn + .listicle-section {
  margin-top: 3rem;
}

section:not(.n4-audio-file) + .n4-audio-file,
.n4-audio-file + section:not(.n4-audio-file) {
  margin-top: 3rem !important;
}

.desktop-ad-auto + .mkdn,
.mobile-ad-auto + .mkdn {
  margin-top: 2rem !important;
}

.mkdn + .desktop-ad-auto,
.mkdn + .desktop-ad-auto + .mobile-ad-auto {
  margin-top: 3rem !important;
}

.mobile-ad-auto + .mkdn {
  margin-top: 2rem !important;
}

.pointer {
  cursor: pointer;
}

p a,
.n4-markdown-blok li a {
  color: #4a7fe5;
  word-break: break-word;
}

p a,
.n4-markdown-blok li a,
.brand-releases li a {
  &:hover {
    color: #294984;
    --ht-shadow: inset 0 -0.5em 0 0 #cbddf8;
    --ht-shadow-colored: inset 0 -0.5em 0 0 var(--ht-shadow-color);
    box-shadow: var(--ht-ring-offset-shadow, 0 0 #0000), var(--ht-ring-shadow, 0 0 #0000), var(--ht-shadow);
  }
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* TODO: Move this to somewhere relevant. It's important. */
.nav-product-wrapper + .nav-custom-link-wrapper .custom-link-container {
  top: 2.5rem;
  @media (max-width: 1200px) {
    top: 0;
  }
}

/* Handles title and subtitle on the news page. Seems stupid, so let's fix it */
.n4-container-inner > .n4-markdown > .n4-markdown-blok > h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  max-width: 1344px;
  margin: 4rem auto 1rem;
  line-height: 1.2;
  font-weight: 600;
  @media (max-width: 600px) {
    margin: 2rem auto 1rem;
  }
}
.n4-container-inner > .n4-markdown > .n4-markdown-blok h2 {
  font-weight: 350;
  font-size: 1.4rem;
  color: rgb(67 81 107);
  max-width: 1344px;
  margin: auto;
  margin-bottom: -2rem;
}

.n4-markdown-blok h2:has(+ h3) {
  text-decoration: underline;
}

.hide-number-spin-button::-webkit-outer-spin-button,
.hide-number-spin-button::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
