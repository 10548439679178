$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/variables' as variables;
@use '../../../../styles/colors' as colors;

nav.topbar-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  font-family: var(--hankenGrotesk-font);

  &.home-page {
    padding: 0 48px;
    max-width: 100%;

    @media #{variables.$xs} {
      padding: 0 1rem;
      max-width: calc(100vw - 10px);
    }
  }

  @media #{variables.$xs} {
    width: 1440px;
    max-width: calc(100vw - 10px);
  }

  @media #{variables.$sm} {
    max-width: 100%;
  }

  @media #{variables.$md} {
    //max-width: 768px;
  }

  @media #{variables.$lg} {
    //max-width: 992px;
  }

  @media #{variables.$laptop, variables.$desktop, variables.$desktop-to-wide, variables.$desktop-wide} {
    width: 100%;
    max-width: 100%;
  }
}

a.logo {
  display: flex;
  align-items: center;
  background-image: url('/images/logo.svg');
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  min-width: 13rem;
  height: 1.75rem;
  margin-right: 0;
  min-height: 3rem;

  &--white {
    background-image: url('/images/logo-white.svg');
  }

  @media #{variables.$xs} {
    min-width: unset;
    max-width: 13rem;
    width: 100%;
    margin-right: 1.25rem;
  }
}

div.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6rem;
  padding: 1rem 3rem;
  width: 100%;
  background-color: inherit;

  &.home-page {
    padding: 1rem 0;
  }

  @media #{variables.$xs, variables.$md} {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    gap: 0rem;
  }

  @media #{variables.$lg} {
    // gap: 2rem;
  }

  a,
  button {
    display: flex;
    justify-content: center;
    height: 46px;

    @media #{variables.$xs} {
      height: 42px;
    }
  }

  .button-loader {
    min-width: 13rem;
  }

  button.hamburger {
    position: relative;
    border: none;
    display: flex;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: none;

    &--white {
      i {
        filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(104%) contrast(106%) !important;
      }
    }

    i {
      position: absolute;
      min-width: 1.125rem;
      min-height: 1.25rem;
      display: flex;
      z-index: 4;
      filter: invert(10%) sepia(57%) saturate(1731%) hue-rotate(198deg) brightness(95%) contrast(92%);
    }

    &:hover {
      background: #3b4e6b;

      i {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(285deg) brightness(102%) contrast(102%);
      }
    }
  }

  button.search {
    position: relative;
    border: none;
    display: flex;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: transparent;
    border: 0.857px solid var(--Navy-Accents-Navy---20, rgba(20, 37, 70, 0.2));

    &--white {
      border-color: rgba($color: white, $alpha: 0.2);
      &:hover {
        border-color: rgba($color: white, $alpha: 0.6) !important;
      }

      i {
        filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(104%) contrast(106%) !important;
      }
    }

    i {
      position: absolute;
      min-width: 1.25rem;
      min-height: 1.25rem;
      display: flex;
      z-index: 4;
      filter: invert(10%) sepia(57%) saturate(1731%) hue-rotate(198deg) brightness(95%) contrast(92%);
    }

    &:hover {
      border: 0.857px solid var(--Navy-Accents-Navy---60, rgba(20, 37, 70, 0.6));
    }

    &--close {
      background: none;
      border: none;
      padding-right: 0.063rem;

      i {
        background-position: center;
        filter: invert(10%) sepia(57%) saturate(1731%) hue-rotate(198deg) brightness(95%) contrast(92%);
      }

      &:hover {
        border: none;
      }
    }
  }
}
