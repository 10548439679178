$imgix: 'https://ht-prod.imgix.net';
.ht-product-info {
  &-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &-button {
    width: 100% !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.48px;
  }
}
