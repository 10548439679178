$imgix: 'https://ht-prod.imgix.net';
@use '../../styles/colors' as colors;
@use '../../styles/mixins' as functionsAndMixins;
@use '../../styles/variables' as variables;

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 8%;
  width: 100%;
  // height: 48px;
  gap: 0.4rem;
  font-family: var(--hankenGrotesk-font);
  font-size: 17px;
  //font-weight: 350;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.01rem;

  @media #{variables.$xs} {
    font-size: 15px;
    font-weight: 350;
  }

  i,
  svg {
    align-self: center;
    fill: currentColor;
  }

  span.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    max-width: 18rem;
  }

  &--primary {
    width: auto;
    // text-wrap: nowrap;
    color: colors.$white;
    background-color: colors.$primary-blue;
    box-shadow: none;
    border-radius: 1.7rem;
    cursor: pointer;

    &:hover {
      background-color: colors.$navy-blue;
      color: colors.$white;
      box-shadow: none;
    }

    &:disabled {
      background-color: colors.$primary-blue;
      opacity: 0.4;
      box-shadow: none;
      cursor: not-allowed;
      max-height: 48px;
    }

    i {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(111%) contrast(100%);
      transform: scale(1.25);
    }
  }

  &--secondary {
    justify-content: space-between;
    gap: 1.25rem;
    width: auto;
    text-wrap: nowrap;
    color: colors.$navy-blue;
    background-color: colors.$white;
    border: 1px solid rgba(20, 37, 70, 0.2);
    box-shadow: none;
    border-radius: 3.75rem;
    cursor: pointer;

    &:hover {
      border: 1px solid colors.$primary-blue;
      color: colors.$navy-blue;
      box-shadow: none;
    }

    &:disabled {
      border: 1px solid rgba(20, 37, 70, 0.2);
      opacity: 0.4;
      box-shadow: none;
      cursor: not-allowed;
    }

    i {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(111%) contrast(100%);
      transform: scale(1.25);
    }
  }

  &--tertiary {
    justify-content: space-between;
    gap: 1.25rem;
    width: auto;
    text-wrap: nowrap;
    color: colors.$white;
    background-color: colors.$navy-blue;
    border: 1px solid colors.$white;
    box-shadow: none;
    border-radius: 3.75rem;
    cursor: pointer;

    &:hover {
      border: 1px solid colors.$navy-blue;
      box-shadow: none;
    }

    &:disabled {
      opacity: 0.4;
      box-shadow: none;
      cursor: not-allowed;
    }

    i {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(111%) contrast(100%);
      transform: scale(1.25);
    }
  }

  &--icon {
    background: inherit;
    color: inherit;
    border: inherit;
    outline: inherit;

    i {
      pointer-events: none;
    }
  }

  &--full {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  div.center {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
}
