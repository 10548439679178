$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/colors' as colors;
@use '../../../../../styles/variables' as variables;

.subtitle {
  color: colors.$navy-blue;

  line-height: 140%;
  letter-spacing: -0.03375rem;
}

.product-feature-list {
  margin: 1.25rem 0 0;
  padding-left: 0.1rem;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  &-item {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    gap: 1rem;

    &-label {
      color: #4f4f4f;

      font-size: 0.85rem;
      font-style: normal;
      line-height: 130%;
      letter-spacing: -0.0225rem;
      @media #{variables.$xs} {
        font-size: 1rem;
      }
    }

    &-value {
      color: colors.$navy-blue;

      font-size: 1rem;
      font-weight: 450;
      font-style: normal;
      line-height: 140%;
      letter-spacing: -0.03rem;
      @media #{variables.$xs} {
        font-size: 1.15rem;
        font-weight: 600;
      }
    }
  }
}
