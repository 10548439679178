$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

section.n4-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 3rem 0 3rem;

  @media #{variables.$xs} {
    padding: 0.75rem 1.5rem;
  }

  @media #{variables.$sm} {
    // max-width: 540px;
  }

  @media #{variables.$md} {
    // max-width: 720px;
  }

  @media #{variables.$lg} {
    //max-width: 960px;
  }

  @media #{variables.$laptop, variables.$desktop, variables.$desktop-to-wide, variables.$desktop-wide} {
    width: 100%;
    max-width: 100%;
  }
  @media #{variables.$lg, variables.$laptop, variables.$desktop} {
    flex: 0 0 auto;
    width: 100%;
  }
}

section.n4-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  @media #{variables.$xs, variables.$md} {
    gap: 1rem;
  }
  section.subscribe {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    @media #{variables.$xs, variables.$md} {
      flex-direction: column;
      gap: 1rem;
    }

    img {
      max-width: none;
      width: 48vw;
      height: 25rem;
      border-radius: 1.25rem;
      object-fit: cover;
      @media #{variables.$xs, variables.$md} {
        height: 100%;
        width: 100%;
      }
    }

    section.subscribe-details {
      max-width: 100%;
      padding: 2rem;
      border-radius: 1.25rem;
      background: colors.$sky-blue-400;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 0 0;
      align-self: stretch;

      h5 {
        margin: 0;
        padding: 0;
        max-width: 90%;
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.8rem;
        letter-spacing: -0.02rem;
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        @media #{variables.$above-laptop} {
          //width: 21.75rem;
          font-size: 2.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          letter-spacing: -0.015rem;
        }
        // @media #{variables.$lg} {
        //   font-size: 28px;
        //   line-height: 120%;
        // }
        @media #{variables.$above-lg} {
          padding-bottom: 20px;
          margin-bottom: 0;
        }
        @media #{variables.$xs} {
          font-size: 1.5rem;
          line-height: 1.8rem;
          margin-bottom: 20px;
        }
      }

      section.input-action {
        display: flex;
        align-content: flex-end;

        form {
          display: flex;
          flex-direction: column;
          width: 100%;

          p {
            padding: 0;
            margin: 0;
            color: colors.$error;
          }

          section {
            display: flex;
            gap: 1rem;
            width: 100%;
            margin-top: 0.25rem;
            margin-bottom: 1rem;

            input {
              padding: 0;
              margin: 0;
              border: 0;
              display: flex;
              width: 100%;
              padding: 0.875rem 1.5rem;
              align-items: center;
              gap: 0.5rem;
              flex: 1 0 0;
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 1.3rem;
              font-style: normal;
              font-weight: 400;
              line-height: 2.6rem;
              letter-spacing: -0.013rem;
              border-radius: 1.25rem;

              @media #{variables.$xs} {
                display: flex;
                padding: 0.875rem 1rem;
                align-items: center;
                gap: 0.5rem;
                flex: 1 0 0;
              }

              &::placeholder {
                color: colors.$navy-blue-400;
                opacity: 1;
              }

              &::-webkit-input-placeholder {
                color: colors.$navy-blue-400;
                opacity: 1;
              }

              &:-moz-placeholder {
                color: colors.$navy-blue-400;
                opacity: 1;
              }
            }

            button {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              width: 3.625rem;
              height: 3.625rem;
              background-color: colors.$navy-blue;

              i {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 1rem;
                height: 1.188rem;
                filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(202deg) brightness(100%) contrast(103%);
              }
            }
          }
        }
      }

      span {
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.225rem;
        letter-spacing: -0.00875rem;
      }
    }
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;
    color: inherit;
    line-height: normal;
  }

  section.container {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 30.875rem;
    align-self: stretch;
    border-radius: 1.25rem;
    background-color: colors.$navy-blue;

    @media #{variables.$xs} {
      gap: 2.5rem;
    }

    section.top {
      display: flex;
      width: 100%;
      padding: 3rem;
      align-items: flex-start;
      gap: 1.75rem;

      // @media #{variables.$sm} {
      //   padding: 2rem 1rem 1rem 1rem;
      //   gap: 2.5rem;
      // }

      @media #{variables.$xs, variables.$md} {
        flex-direction: column;
      }

      @media #{variables.$xs} {
        padding: 2.25rem 1.5rem;
        padding-bottom: 0;
      }

      section.copy {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;
        width: fit-content;

        @media #{variables.$laptop, variables.$desktop} {
          width: 32.313rem;
        }

        h3 {
          margin: 0;
          color: colors.$white;
          font-family: var(--hankenGrotesk-font);
          font-size: clamp(1.75rem, 2vw, 2rem);
          font-style: normal;
          font-weight: 400;
          line-height: 2.4rem;
          letter-spacing: -0.02rem;

          @media #{variables.$xs} {
            width: 18.625rem;
            flex-shrink: 0;
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2.4rem;
            letter-spacing: -0.02rem;
          }
        }

        h3.second-caption {
          color: colors.$sky-blue-400;
        }
      }

      section.links {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 1rem;
        @media #{variables.$xs} {
          gap: 2rem 0;
        }
        flex: 1 0 0;
        width: 100%;

        @media #{variables.$laptop, variables.$desktop} {
          gap: 1.625rem;
        }

        @media #{variables.$md} {
          max-height: 25rem;
          overflow: auto;
        }

        @media #{variables.$xs} {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }

        section.column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          flex: 1 0 0;

          @media #{variables.$desktop-wide} {
            max-width: 13vw;
          }

          @media #{variables.$xs} {
            width: 10.125rem;
          }

          h4 {
            padding: 0;
            align-self: stretch;
            color: colors.$white;
            font-family: var(--hankenGrotesk-font);
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
            letter-spacing: -0.01rem;
            margin-bottom: 0;

            @media #{variables.$xs} {
              font-weight: 500;
              font-size: 1.3rem;
            }
          }

          ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;

            li {
              display: flex;
              padding: 0.5rem 0rem;
              align-items: flex-start;
              align-self: stretch;
              @media #{variables.$xs} {
                max-width: 90%;
              }
              a {
                font-size: 1rem;
                @media #{variables.$xs} {
                  font-weight: 500;
                  font-size: 1.1rem;
                }
              }
            }
          }
        }
      }
    }

    section.bottom {
      display: flex;
      width: 100%;
      padding: 2rem;
      justify-content: space-between;
      align-items: center;

      @media #{variables.$xs} {
        padding: 2.25rem 1.5rem;
        padding-top: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
      }

      a.logo {
        display: flex;
        align-items: center;
        object-fit: contain;
        width: 14rem;
        height: 2.3125rem;

        @media #{variables.$xs} {
          align-items: flex-start;
        }
      }
    }
  }

  section.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    @media (max-width: 600px) {
      /* Adjust the breakpoint as needed */
      display: block;
    }

    section.copyright {
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3125rem;
      letter-spacing: -0.00875rem;
      @media (max-width: 600px) {
        /* Adjust the breakpoint as needed */
        width: 100%; /* Make the list take up full width */
        margin-top: 1rem; /* Adjust spacing to push it down */
      }
    }

    ul.terms-links {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      li {
        a {
          font-size: 0.875rem;
        }
      }
      @media (max-width: 600px) {
        /* Adjust the breakpoint as needed */
        width: 100%; /* Make the list take up full width */
        margin-top: 1rem; /* Adjust spacing to push it down */
      }
    }
  }
}

.home-footer-container {
  padding: 0 !important;
  max-width: 1713px !important;
  padding-top: 0px !important;
  @media #{variables.$md, variables.$laptop, variables.$desktop, variables.$desktop-to-wide, variables.$desktop-wide} {
    // padding-top: 10px !important;
  }
  max-width: calc(100% - 96px) !important;
  @media #{variables.$xs} {
    max-width: calc(100% - 32px) !important;
  }
  @media #{variables.$sm} {
    max-width: calc(100% - 32px) !important;
  }
  @media #{variables.$desktop-wide} {
    max-width: calc(100% - 192px) !important;
  }
}
