$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

section.n4-product-card {
  display: flex;
  max-width: 100%;
  padding: 2rem 2rem 2.5rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  border-radius: 1.25rem;
  background: colors.$white;
  box-shadow: 4px 12px 40px 0px rgba(0, 0, 0, 0.1);
  margin: 3rem 0 3rem 0;

  @media #{variables.$xs} {
    display: flex;
    padding: 24px 20px 40px;
    flex-direction: column;
    align-self: flex-start;
    max-width: 100%;
    width: 100%;
  }

  div.container {
    display: flex;
    gap: 3rem;
    align-items: flex-start;
    align-self: stretch;

    @media #{variables.$xs} {
      display: flex;
      flex-direction: column;
      // align-items: center;
      gap: 1.5rem;
      // align-self: stretch;
    }

    section.left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      max-width: 16.325rem;
      a {
        display: flex;
        justify-content: center;
        width: 100%;

        figure {
          margin: 0;
          padding: 0;
          position: relative;
          display: flex;
          padding: 0 2.25rem;
          flex-direction: column;
          align-items: flex-start;
          border-radius: 1.25rem;
          margin: 0;
          aspect-ratio: 1920/1080;
          max-height: 221px;

          img {
            object-fit: contain;
            border-radius: 1.25rem;
            width: auto;
            height: auto;
          }

          @media #{variables.$xs} {
            align-items: center;

            img {
              object-fit: contain;
              border-radius: 1.25rem;
              width: 15rem;
              height: auto;
            }
          }
        }
      }

      @media #{variables.$xs} {
        display: block;
        max-width: none;
        width: 100%;
        // display: flex;
        // flex-direction: column;
        // align-items: normal;
        // gap: 0.375rem;
        // align-self: stretch;
      }
    }

    section.right {
      width: 27.5rem;
      padding-top: 1.5rem;

      @media #{variables.$xs} {
        display: flex;
        flex-direction: column;
        // gap: 0.375rem;
        align-self: stretch;
        width: 100%;
        padding-top: 0;
      }

      section.details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;

        h4 {
          margin: 0 0 0.38rem;
          padding: 0;
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 2.2rem;
          font-weight: 500;
          font-style: normal;
          line-height: 2.6rem;
          letter-spacing: -0.02rem;
          align-self: stretch;
        }

        span {
          display: flex;
          color: colors.$primary-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 1.3rem;
          font-weight: normal;
          line-height: 2.6rem;
          letter-spacing: -0.039rem;

          section {
            display: flex;
            gap: 0;
            min-width: fit-content;
            margin: 0 0.25rem;
          }
        }

        p {
          margin: 0.75rem 0 0;
          padding: 0 0 1.9rem;
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 1.1rem;
          font-style: normal;
          line-height: 1.7rem;

          @media #{variables.$xs} {
            font-size: 18px;
            line-height: 1.65;
          }
        }
      }

      div.animate {
        width: 100%;

        ul.audio {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          align-self: stretch;
          // overflow-y: auto;

          @media #{variables.$xs} {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            align-self: stretch;
            // margin-bottom: 2rem;
          }

          li {
            margin-top: -0.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;

            button {
              background: inherit;
              border: none;
              outline: none;
              padding: 0;
              margin: 0;
              border-radius: 100%;
              box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05);

              figure.audio-icon-circle {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 2rem;
                min-height: 2rem;
                border-radius: 100%;
                margin: 0;

                i {
                  width: 48px;
                  height: 49px;
                }

                figure.audio-icon-play-or-pause {
                  position: absolute;
                  top: 19px;
                  right: 0;
                  bottom: 0;
                  left: 21px;
                  margin: auto;

                  i {
                    width: 0.8rem;
                    height: 0.8rem;
                  }

                  @media #{variables.$xs} {
                    top: 1.125rem;
                    left: 1.375rem;

                    i {
                      width: 17px;
                      height: 17px;
                    }
                  }
                }
              }
            }

            section.audio-details {
              p {
                color: colors.$navy-blue;
                font-family: var(--hankenGrotesk-font);
                font-size: 1rem;
                font-weight: normal;
                line-height: 140%; /* 1.4rem */
                letter-spacing: -0.03rem;
                @media #{variables.$xs} {
                  font-size: 1.2rem;
                  font-weight: 450;
                }
              }
            }
          }
        }

        + a {
          justify-content: center;

          @media #{variables.$xs} {
            padding: 10px 18px;
            height: auto;
          }
        }
      }
    }
  }
}

:global(.article-within-product) {
  @media (min-width: 1487px) {
    section.n4-product-card {
      margin-left: -50px;
      margin-right: -50px;
      max-width: none;
    }
  }
  @media (max-width: 1255px) {
    section.n4-product-card {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      max-width: 100%;
      width: 100%;
    }

    div.container {
      display: flex;
      flex-direction: column;
      gap: 0 !important;
    }

    section.left {
      position: relative;
      top: 0;
      // a {
      //   padding-top:1.5rem;
      //   figure {
      //     img {
      //       max-height:180px;
      //     }
      //   }
      // }
    }

    section.right {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      width: 100% !important;
      padding-top: 1rem !important;
    }

    section.details {
      p {
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
}
