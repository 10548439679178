$imgix: 'https://ht-prod.imgix.net';
@use '../../styles/variables' as variables;
@use '../../styles/mixins' as functionsAndMixins;
@use '../../styles/colors' as colors;

section.share {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  a,
  button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid colors.$navy-blue-200;
    background: rgba(255, 255, 255, 0.1);
    min-width: 44px;
    min-height: 44px;
    text-align: center;

    &:hover,
    &:active {
      border: 1px solid functionsAndMixins.shade(colors.$gray-gainsboro, 15%);
      color: functionsAndMixins.shade(colors.$gray, 15%);

      i {
        filter: invert(100%) sepia(80%) saturate(630%) hue-rotate(163deg) brightness(60%) contrast(86%);
      }
    }
  }

  button.copy {
    transform: rotate(135deg);

    i {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      filter: invert(11%) sepia(25%) saturate(3821%) hue-rotate(199deg) brightness(87%) contrast(91%);
    }
  }

  a.instagram,
  a.linkedin,
  a.youtube,
  a.twitter,
  a.facebook {
    i {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      filter: invert(11%) sepia(25%) saturate(3821%) hue-rotate(199deg) brightness(87%) contrast(91%);
    }
  }

  a.facebook {
    i {
      left: 0.375rem;
    }
  }

  a.instagram {
    i {
      left: 0.125rem;
    }
  }

  &--dark {
    a.instagram,
    a.linkedin,
    a.youtube,
    a.twitter,
    a.facebook {
      background-color: transparent;
      border: 1px solid colors.$white;

      i {
        filter: invert(1);
      }
    }
  }
}
