$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

section.n4-image {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3rem 0 3rem;
  max-width: 810px;

  @media #{variables.$xs} {
    align-self: flex-start;
    max-width: 100%;
  }

  figure.image {
    position: relative;
    border-radius: 1.25rem;
    margin: 0;
    display: flex;
    justify-content: center;

    @media #{variables.$xs} {
      aspect-ratio: auto;
      justify-content: flex-start;
    }
  }

  figure.image-no-rounding {
    border-radius: 0;
  }

  section.caption {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 1.3125rem;
    border-left: 2px solid colors.$navy-blue;
    padding-left: 0.5rem;

    span {
      flex: 1 0 0;
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      @media #{variables.$xs} {
        font-weight: 350;
        font-size: 1.15rem;
      }
    }
  }
}

section.desktop-ad-auto {
  // background: white;
  // padding: 9px;
  // border-color: #d4d4d4;
  border-radius: 1.25rem;
  margin: 1rem 0 0;
  figure::before {
    content: 'ADVERTISEMENT';
    position: absolute;
    letter-spacing: 0.8px;
    top: -23px; /* 32 with padding */
    left: 50%;
    transform: translateX(-50%);
    font-size: 9px; /* Adjust font size as needed */
    color: #ccc; /* Adjust text color as needed */
    font-family: var(--hankenGrotesk-font);
    padding: 2px 4px; /* Adjust padding as needed */
    z-index: 1;
  }
  display: block;
  img {
    border-radius: 11px !important;
  }
  @media #{variables.$xs} {
    display: none;
  }
}
section.mobile-ad-auto {
  // border: 1px solid;
  // background: white;
  // padding: 9px;
  // border-color: #d4d4d4;
  border-radius: 1.25rem;
  margin: 1rem 0 0;
  figure::before {
    content: 'ADVERTISEMENT';
    position: absolute;
    letter-spacing: 0.8px;
    top: -23px; /* 32 with padding */
    left: 50%;
    transform: translateX(-50%);
    font-size: 9px; /* Adjust font size as needed */
    color: #ccc; /* Adjust text color as needed */
    font-family: var(--hankenGrotesk-font);
    padding: 2px 4px; /* Adjust padding as needed */
    z-index: 1;
  }
  display: block;
  img {
    border-radius: 11px !important;
  }
  @media #{variables.$above-md} {
    display: none;
  }
}
