$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

$mobile-layout-padding: 16px;
$desktop-layout-padding: 48px;
$wide-screen-layout-padding: 96px;

.ht-multi-carousel {
  &-custom-dot-list {
    right: auto !important;
    align-items: center;
    gap: 11.321px;
    height: 54px;

    div {
      cursor: pointer;
    }

    @media #{variables.$xs} {
      max-width: 60%;
      gap: 6px;
      height: 48px;
    }
  }

  &-slider {
    margin-bottom: 48px !important;
    &-fit-content {
      transform: translate3d(0px, 0px, 0px) !important;
    }
  }

  &-button-group {
    display: flex;
    align-items: flex-start;
    position: absolute;
    bottom: 1px;
    right: 0;
    z-index: 1;
    gap: 16px;

    @media #{variables.$xs} {
      margin-right: $mobile-layout-padding;
    }

    @media #{variables.$above-md} {
      margin-right: $desktop-layout-padding;
    }

    @media #{variables.$desktop-wide} {
      margin-right: $wide-screen-layout-padding;
    }

    .mobile {
      gap: 12px;
    }

    .arrow-to-left {
      transform: rotate(180deg);
    }

    &-button {
      display: flex;
      justify-content: flex-end;
      border: 1px solid colors.$navy-blue !important;
      background: colors.$orange-50;
      gap: 10px;
      padding: 15px;
      max-width: 54px;
      max-height: 54px;
      border-radius: 60px;

      &--inactive {
        opacity: 20% !important;
        cursor: default !important;
      }
    }

    .mobile {
      width: 48px;
      height: 48px;
    }
  }

  .slider {
    // padding-bottom: 48px;
    display: flex;
  }
}

.hearing-aids-carousel {
  li {
    max-width: 389px;

    @media #{variables.$xs} {
      max-width: 377px;
    }

    @media #{variables.$desktop-wide} {
      max-width: 456px;
    }
  }

  .slider {
    @media #{variables.$xs} {
      //padding-bottom: 56px !important;
    }

    @media #{variables.$desktop-wide} {
      padding-bottom: 64px !important;
    }
  }

  .ht-multi-carousel-slider-fit-content {
    transform: translate3d(0px, 0px, 0px) !important;
  }

  .ht-multi-carousel {
    &-button-group {
      margin-bottom: 1px;

      @media #{variables.$xs} {
        margin-right: 20px;
      }

      &-button {
        &--inactive {
          opacity: 40% !important;
        }
      }
    }
  }
}

.hero-lower-card-carousel {
  .slider {
    @media #{variables.$xs} {
      padding-bottom: 2rem !important;
    }

    @media #{variables.$above-md} {
      padding-bottom: 32px !important;
    }
  }

  .ht-multi-carousel-slider-fit-content {
    transform: translate3d(0px, 0px, 0px) !important;
  }

  .ht-multi-carousel {
    &-custom-dot-list {
      display: none;
    }

    &-button-group {
      left: 0;

      @media #{variables.$xs} {
        gap: 12px;
      }

      @media #{variables.$desktop} {
        display: none;
      }
    }
  }
}

.custom-dots {
  overflow: visible;

  .slider {
    padding-bottom: 20px;
  }

  .ht-multi-carousel {
    &-slider {
      margin-bottom: 40px !important;
    }

    &-button-group {
      display: none;
    }

    &-custom-dot-list {
      width: 100%;
      height: auto;
      max-width: 100%;
      gap: 12px;
    }
  }
}

.custom-dots-with-arrows {
  overflow: visible;

  .slider {
    padding-bottom: 20px;
  }

  .ht-multi-carousel {
    &-slider {
      margin-bottom: 40px !important;
    }

    &-button-group {
      display: none;
    }

    &-custom-dot-list {
      width: 60%;
      height: auto;
      bottom: 12px;
      max-width: 100%;
      gap: 12px;
    }
  }
}
