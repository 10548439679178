html {
  font-size: clamp(12px, 2vw, 16px);
  line-height: 150%;
  @media #{$xs} {
    font-size: clamp(14px, 1.4vw, 20px);
  }
  @media #{$xs}, #{$sm}, #{$md} {
    .container {
      padding: 0 1.25rem !important;
    }
  }
  scroll-behavior: smooth;
}

body {
  // which of these is right?
  // font-size: 1rem;
  font-size: 1.1rem;
  // which of these is right?
  // line-height: 1.7;
  line-height: inherit;
  color: $body-color;
  position: relative;
}

.body-color {
  color: $body-color;
}

.light-body-color {
  color: $light-body-color;
}

.body-font {
  font-family: $body-font;
}

a {
  // text-decoration: none;
  // color: $link-color;

  // &:hover {
  //   color: #2f8fd4;
  // }

  &.link {
    color: $heading-color;
    border-bottom: 1px solid $heading-color;
  }

  &.underlined {
    border-bottom: 1px solid $primary-color;
  }
  &.w-right-icon {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -0.875rem;
      top: 5px;
      width: 7px;
      height: 0.75rem;
      background-image: url('/images/design/right-arrow-link-icon.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.active {
    font-weight: 600 !important;
  }
}
.text-primary {
  color: $primary-color !important;
}

.heading-color {
  color: $heading-color !important;
}

// default

.heading-font {
  font-family: $heading-font;
}

.primary-color {
  color: $primary-color;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

.bg-lightest {
  background-color: rgba(242, 242, 242, 0.5);
}
