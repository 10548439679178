$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/colors' as colors;

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@mixin skeleton-animation {
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: loading 1.25s infinite;
}

div.skeleton {
  display: block;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100%;
  min-width: 100%;
  border: 1px solid colors.$gray-lighter;
  @include skeleton-animation();

  &--circle {
    border-radius: 50%;
  }
}
