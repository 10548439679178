$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/variables' as variables;
@use '../../../../styles/colors' as colors;

section.article {
  &--row {
    display: flex;
    max-width: 19.625rem;
    flex-direction: column;
    gap: 1.1875rem;
    overflow: hidden;
    width: 100%;

    figure {
      margin: 0;
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
      aspect-ratio: 314/204;
      border-radius: 0.813rem;
      max-width: 19.625rem;
      width: 100%;
      height: 100%;

      @media #{variables.$lg} {
        max-height: 131px;
        min-height: 131px;
      }

      @media #{variables.$laptop} {
        max-height: 165px;
        min-height: 165px;
        font-size: 1.125rem;
      }

      @media #{variables.$desktop} {
        max-height: 204px;
        min-height: 204px;
      }

      div.skeleton {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;

        @media #{variables.$lg} {
          max-height: 131px;
          min-height: 131px;
        }

        @media #{variables.$laptop} {
          max-height: 165px;
          min-height: 165px;
          font-size: 1.125rem;
        }

        @media #{variables.$desktop} {
          max-height: 204px;
          min-height: 204px;
        }

        @media #{variables.$desktop} {
          max-height: 204px;
          min-height: 204px;
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      div.icon {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        z-index: 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: none;
        border-radius: 100%;
        background-color: colors.$white;
        box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15);
        width: 2.5rem;
        height: 2.5rem;

        i {
          width: 1rem;
          height: 1.188rem;
          filter: invert(13%) sepia(47%) saturate(1050%) hue-rotate(183deg) brightness(90%) contrast(96%);
        }
      }
    }

    section.details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.375rem;
      p.description {
        padding: 0;
        margin: 0;
        color: colors.$navy-blue-600;
        font-family: var(--hankenGrotesk-font);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.225rem;
        letter-spacing: -0.02625rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @media #{variables.$xs, variables.$md} {
          -webkit-line-clamp: 2;
        }
      }
      h4 {
        padding: 0;
        margin: 0;
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        flex-wrap: wrap;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4625rem;
        letter-spacing: -0.03375rem;
        @media #{variables.$xs, variables.$md} {
          -webkit-line-clamp: 3;
          font-weight: 500;
          font-size: 1.25rem;
        }
        @media #{variables.$lg} {
          font-size: 0.875rem;
        }

        @media #{variables.$laptop} {
          font-size: 1.125rem;
        }
      }

      p {
        padding: 0;
        margin: 0;
        color: colors.$navy-blue-800;
        font-family: var(--hankenGrotesk-font);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.225rem;
        letter-spacing: -0.02625rem;
        overflow-y: auto;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  &--column {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: stretch;
    max-width: 29.938rem;
    max-height: 7.5rem;
    gap: 1.1875rem;
    overflow-y: hidden;

    @media #{variables.$xs, variables.$md} {
      max-width: 100%;
      max-height: 6rem;
    }

    figure {
      margin: 0;
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
      aspect-ratio: 144/108;
      max-height: 7.5rem;
      border-radius: 0.625rem;
      min-width: 10rem;
      max-width: 10rem;
      border-radius: 0.813rem;

      @media #{variables.$xs, variables.$md} {
        aspect-ratio: 128/96;
        max-height: 6rem;
        min-width: 8rem;
        max-width: 8rem;
      }

      div.skeleton {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      div.placeholder {
        min-width: 10rem;
        max-width: 10rem;
        border-radius: 0.813rem;
        background-color: colors.$navy-blue-200;

        @media #{variables.$xs, variables.$md} {
          aspect-ratio: 128/96;
          max-height: 6rem;
          min-width: 8rem;
          max-width: 8rem;
        }
      }

      div.icon {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        z-index: 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: none;
        border-radius: 100%;
        background-color: colors.$white;
        box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15);
        width: 2.5rem;
        height: 2.5rem;

        @media #{variables.$xs, variables.$md} {
          display: none;
        }

        i {
          width: 1rem;
          height: 1.188rem;
          filter: invert(13%) sepia(47%) saturate(1050%) hue-rotate(183deg) brightness(90%) contrast(96%);
        }
      }
    }

    section.details {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      p.description {
        padding: 0;
        margin: 0;
        color: colors.$navy-blue-600;
        font-family: var(--hankenGrotesk-font);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.225rem;
        letter-spacing: -0.02625rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media #{variables.$xs, variables.$md, variables.$lg, variables.$laptop} {
          display: none;
        }
      }
      h4 {
        padding: 0 0 3px;
        @media #{variables.$xs, variables.$md, variables.$lg, variables.$laptop} {
          padding: 0;
        }
        margin: 0;
        color: colors.$navy-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4rem;
        letter-spacing: -0.03rem;
        flex-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media #{variables.$xs, variables.$md} {
          -webkit-line-clamp: 3;
          font-weight: 500;
          font-size: 1.25rem;
        }
      }

      div.date {
        display: flex;
        color: colors.$primary-blue;
        font-family: var(--hankenGrotesk-font);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.225rem;
        letter-spacing: -0.02625rem;
        padding-top: 7px;
        @media #{variables.$xs, variables.$md, variables.$lg, variables.$laptop} {
          padding: 5px 0;
        }
      }
    }
  }
}

div.wrapper {
  display: contents;

  a {
    display: flex;
  }
}
